import { StringEnum } from "@artesa/model-compiler";
export const assignmentCustomAttributeTypes = StringEnum([
  "text",
  "string",
  "integer",
  "float",
  "double",
  "date",
  "date_only",
  "time",
  "boolean"
]);
