import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../typebox/index.js";
export const CustomViewData = defineSchema(
  {
    routeKey: Type.String(),
    servicePath: Type.Optional(Nullable(Type.String())),
    data: Type.Any(),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  "custom-view-data",
  (r) => ({
    company: r.belongsTo("companies"),
    view: r.belongsTo("custom-views")
  })
);
