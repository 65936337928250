import { defineSchema } from "@artesa/model-compiler";
import { Type } from "../typebox/index.js";
export const GangUser = defineSchema(
  {
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "gang-users",
  (r) => ({
    company: r.belongsTo("companies"),
    user: r.belongsTo("users"),
    gang: r.belongsTo("gangs")
  })
);
