import { viewNameEnum } from "@artesa/shared";

export default (): RouteRecordRaw[] => [
  {
    path: "check",
    name: "MainCheck",
    redirect: () => ({ name: viewNameEnum["ViewTimeSheetDays"] }),
    children: [
      {
        path: "office",
        name: "ViewAssemblyOffice",
        redirect: () => ({ name: viewNameEnum["ViewAssignmentWorkPeriodsExport"] }),
        children: [
          {
            path: "export",
            name: viewNameEnum["ViewAssignmentWorkPeriodsExport"],
            component: () => import("./ViewWorkPeriodsExport.vue"),
            meta: {
              name: "Arbeitszeit&shy;auswertung",
              icon: "file-export",
              permission: true,
            },
          },
        ],
      },
      {
        path: "time-sheets",
        name: viewNameEnum["ViewTimeSheetDays"],
        component: () => import("./time-sheet/ViewTimeSheet.vue"),
        meta: {
          name: "Stunden&shy;zettel",
          icon: "business-time",
          permission: true,
        },
      },
    ],
  },
];
