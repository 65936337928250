import { viewNameEnum } from "@artesa/shared";
import parseProps from "vue-router-parse-props";

export default (): RouteRecordRaw[] => [
  {
    path: "/settings/:settingName?",
    name: viewNameEnum.ViewSettings,
    component: () => import("./settings/_views/ViewSettings.vue"),
    props: route => {
      return {
        settingName: route.params.settingName || "users",
      };
    },
    meta: {
      name: "Einstellungen",
      icon: "cogs",
      permission: true,
    },
  },
  {
    path: "/user/profile/:id",
    name: viewNameEnum.ViewUserProfile,
    component: () => import("./users/_views/ViewUserProfile.vue"),
    props: parseProps({
      id: { type: val => (val === "me" ? val : parseInt(val, 10)) },
    }),
    meta: {
      name: "Mitarbeiter&shy;profil",
      icon: ["far", "address-card"],
      permission: true,
    },
  },
  {
    path: "/user/absences",
    name: viewNameEnum.ViewUserAbsences,
    component: () => import("../components/user-absences/ViewUserAbsences.vue"),
    meta: {
      name: "Abwesenheiten",
      icon: ["far", "calendar-times"],
      permission: true,
    },
  },
];
