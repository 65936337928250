import { defineSchema } from "@artesa/model-compiler";
import { Nullable, StringEnum, Type } from "../../typebox/index.js";
import {
  UserSettingXNotificationSchema,
  UserSettingsXWebPushSchema
} from "../notifications/user-settings-x-notifications.js";
import { assignmentNameToShowValues } from "../company-settings/index.js";
export const UserSetting = defineSchema(
  {
    notifications: Type.Optional(UserSettingXNotificationSchema),
    webPush: Type.Optional(Type.Array(UserSettingsXWebPushSchema)),
    assignmentNameToShow: Type.Optional(
      Nullable(StringEnum(assignmentNameToShowValues, { default: null }))
    ),
    assignmentNameToShowManufacturing: Type.Optional(
      Nullable(StringEnum(assignmentNameToShowValues, { default: null }))
    ),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "user-settings",
  (r) => ({
    company: r.belongsTo("companies")
  })
);
