import { viewNameEnum } from "@artesa/shared";
import parseProps from "vue-router-parse-props";
import { format as formatDate } from "date-fns";

export default (): RouteRecordRaw[] => [
  {
    path: "do",
    name: viewNameEnum.ViewDoing,
    redirect: to => {
      return {
        name: "ViewAssemblyAssemblersWithDate",
        params: {
          day: formatDate(new Date(), "yyyy-MM-dd"),
        },
        query: to.query,
      };
    },
    meta: {
      name: "Erfassung",
      icon: "clipboard-list",
      permission: true,
    },
    children: [
      {
        path: ":day",
        name: "ViewAssemblyAssemblersWithDate",
        component: () => import("./_views/ViewDoing.vue"),
        // props: true,
        props: parseProps({
          day: { type: str => str && str.toString() },
          "query.t": {
            type: items => {
              if (items && !Array.isArray(items)) {
                items = [items];
              }
              if (!items || !Array.isArray(items)) {
                return [];
              }
              return items.map(x => parseInt(x));
            },
            propKey: "eventTypeIds",
          },
        }),
        meta: {
          name: "Erfassung",
          icon: "clipboard-list",
          permission: viewNameEnum.ViewDoing,
        },
        children: [
          {
            path: ":id",
            name: viewNameEnum.DialogAssemblyAssignmentCard,
            component: () => import("./_views/ViewDoingAssignmentCard.vue"),
            props: parseProps({
              day: String,
              id: Number,
            }),
            meta: {
              permission: true,
              transition: null,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/manufacturing",
    name: "MainManufacturing",
    redirect: () => {
      return { name: viewNameEnum.ViewManufacturingWorkSpace };
    },
    children: [
      {
        path: "shopfloor",
        name: viewNameEnum.ViewShopfloorEntrance,
        component: () => import("./_views/ViewShopfloorEntrance.vue"),
        meta: {
          name: "Tagewerk",
          icon: "industry",
          permission: viewNameEnum.ViewManufacturingWorkSpace,
        },
      },
      {
        path: "shopfloor/:eventTypeId",
        name: viewNameEnum.ViewManufacturingWorkSpace,
        component: () => import("./_views/ViewWorkSpace.vue"),
        props: parseProps({ eventTypeId: Number }),
        beforeEnter(to: any, from: any, next: any) {
          if (!to.params.eventTypeId && to.name !== "ViewShopfloorEntrance") {
            next({ name: "ViewShopfloorEntrance" });
          } else {
            next();
          }
        },
        meta: {
          name: "Tagewerk",
          icon: "industry",
          permission: true,
        },
      },
    ],
  },
];
