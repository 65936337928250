import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable, StringEnum } from "../../typebox/index.js";
import { assignmentStatusCategories } from "./assignment-status-types.types.js";
export const AssignmentStatusType = defineSchema(
  {
    name: Type.String(),
    description: Type.Optional(Nullable(Type.String())),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    category: Type.Optional(Nullable(StringEnum(assignmentStatusCategories))),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-status-types",
  (r) => ({
    company: r.belongsTo("companies")
  })
);
