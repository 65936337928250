import { Type } from "@artesa/model-compiler";
import { DateSchema, StringEnum } from "../typebox/index.js";
export const calculateWorkingTimesServiceMethods = [
  "calculateDuration",
  "calculateWorkingDaysForForAbsoluteDays",
  "calculateWorkingDaysForDateRange"
];
export const timeOfDaySchema = Type.Object(
  {
    hours: Type.Integer({ minimum: 0, maximum: 23 }),
    minutes: Type.Integer({ minimum: 0, maximum: 59 }),
    seconds: Type.Integer({ minimum: 0, maximum: 59 })
  },
  { additionalProperties: false }
);
export const calculateDurationDataSchema = Type.Object(
  {
    companyId: Type.Integer({ minimum: 1 }),
    date: Type.Union([Type.String({ format: "date-time" }), DateSchema()]),
    direction: StringEnum(["forward", "backward"]),
    workDays: Type.Integer({ minimum: 0, maximum: 500 }),
    workingTimePerDay: Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 }),
    isAllDay: Type.Boolean()
  },
  { additionalProperties: false }
);
export const calculateWorkingDaysForAbsoluteDaysDataSchema = Type.Object(
  {
    dates: Type.Array(Type.Union([Type.String({ format: "date-time" }), DateSchema()]), {
      minItems: 1
    }),
    companyId: Type.Number(),
    dateToCompare: Type.Union([Type.String({ format: "date-time" }), DateSchema()])
  },
  { additionalProperties: false }
);
export const calculateWorkingDaysForDateRangeDataSchema = Type.Object(
  {
    companyId: Type.Number(),
    userId: Type.Number(),
    start: Type.Union([Type.String({ format: "date-time" }), DateSchema()]),
    end: Type.Union([Type.String({ format: "date-time" }), DateSchema()]),
    forceCountHolidaysAsWorkdays: Type.Optional(Type.Boolean({ default: false }))
  },
  { additionalProperties: false }
);
