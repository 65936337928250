import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const CustomViewRole = defineSchema(
  {
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  "custom-view-roles",
  (r) => ({
    company: r.belongsTo("companies"),
    view: r.belongsTo("custom-views"),
    role: r.belongsTo("roles")
  })
);
