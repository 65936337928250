import sentry from "./main.plugins/main.sentry";
import { setDefaultOptions } from "date-fns";
import { de as deLocale } from "date-fns/locale";
import { get } from "idb-keyval";
import App from "./App.vue";
import { pinia } from "./store";
import router from "./router/router";
import casl from "./main.plugins/main.casl";
import fontAwesome from "./main.plugins/main.font.awesome";
import primeVue from "./main.plugins/main.prime-vue";
import i18n from "./main.plugins/main.i18n";
import googleMaps from "./main.plugins/main.google-maps";
import debug from "./main.plugins/debug.plugin";
import { useAuthStore } from "./store/auth.store";
import { socket } from "./store/feathers/feathers.client";
import { useGeneralStore } from "./store/general.store";

import "./styles/tailwind.scss";
import "./styles/styles.scss";
import "floating-vue/dist/style.css";
import { initializeStores } from "./store/feathers/services/store-map";
import { changeUrlQueryLocalStorage } from "./migrations/changeUrlQueryLocalStorage";

setDefaultOptions({
  weekStartsOn: 1,
  locale: deLocale,
});

// TODO: Remove this migration after a few weeks
try {
  changeUrlQueryLocalStorage({
    "ViewAssemblyBoard": "ViewPlanningBoard",
    "ViewAssemblyAssemblers": "ViewDoing",
    "ViewAssignmentSearch": "ViewAssignments"
  })
} catch (error) {
  console.error(error);
}

const app = createApp(App)
  .use(sentry(router))
  .use(i18n)
  .use(primeVue)
  .use(fontAwesome)
  .use(googleMaps)
  .use(casl)
  .use(debug)
  .use(pinia);

initializeStores(pinia);

const socketConnectAttempted = new Promise<boolean>(resolve => {
  if (socket.connected) {
    resolve(true);
    return;
  }

  const timeoutConnect = setTimeout(() => {
    resolveFalse();
  }, 7000);

  const resolveTrue = () => {
    clear();
    resolve(true);
  };

  const resolveFalse = () => {
    clear();
    resolve(false);
  };

  function clear() {
    socket.off("connect", resolveTrue);
    socket.off("connect_error", resolveFalse);
    clearTimeout(timeoutConnect);
  }

  socket.once("connect", resolveTrue);
  socket.once("connect_error", resolveFalse);
});

const url = new URL(window.location.href);

if (url.searchParams.has("access_token")) {
  useGeneralStore().skipServiceWorker = true;
  startApp({
    strategy: "readonly-company",
    accessToken: url.searchParams.get("access_token"),
  });
} else if ((await get("feathers-jwt")) != null) {
  startApp();
} else {
  buildApp();
}

async function startApp(authenticateData?: any | false) {
  await socketConnectAttempted;

  if (socket.connected && authenticateData !== false) {
    try {
      const authStore = useAuthStore();
      await authStore.authenticate(authenticateData);
      // eslint-disable-next-line no-empty
    } catch {}
  }

  buildApp();
}

function buildApp() {
  app.use(router);

  const authStore = useAuthStore();
  authStore.setRouter(router);

  if ("clearCacheTimeout" in window) {
    clearTimeout(window.clearCacheTimeout as any);
  }

  app.mount("#vue-app");
}
