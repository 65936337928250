// src/compiler.ts
import { Kind as Kind3, ReadonlyKind as ReadonlyKind3 } from "@sinclair/typebox";
import * as prettier from "prettier";

// src/typebox-type-to-ts.ts
import { Kind, ReadonlyKind } from "@sinclair/typebox";
function tSchemaToTsType(schema) {
  switch (schema[Kind]) {
    case "Union": {
      const union = schema;
      return `${union.anyOf.map((value) => tSchemaToTsType(value)).join(" | ")}`;
    }
    case "Object":
      return tSchemaTObjectToTsType(schema);
    case "RegExp":
    case "String":
      return "string";
    case "Integer":
    case "Number":
      return "number";
    case "Boolean":
      return "boolean";
    case "BigInt":
      return "bigint";
    case "Date":
      return "Date";
    case "Literal": {
      if (schema.type === "number") {
        return `${schema.const}`;
      }
      return `"${schema.const}"`;
    }
    case "Never":
      return "never";
    case "Null":
      return "null";
    case "Undefined":
      return "undefined";
    case "Uint8Array":
      return "Uint8Array";
    case "Unknown":
      return "unknown";
    case "Void":
      return "void";
    case "Any":
      return "any";
    case "Array": {
      const array = schema;
      return `(${tSchemaToTsType(array.items)})[]`;
    }
    case "Intersect": {
      const intersect = schema;
      return `${intersect.allOf.map((value) => tSchemaToTsType(value)).join(" & ")}`;
    }
    case "TemplateLiteral":
      console.warn(`TemplateLiteral gets converted to string`);
      return "string";
    default:
      console.warn(`Failed to convert type ${schema[Kind]} to typescript type`, schema);
      return "any";
  }
}
function tSchemaTObjectToTsType(schema) {
  if (Object.keys(schema.properties).length === 0) {
    return `Record<string, any>`;
  }
  let generatedCode = `{`;
  for (const propertyKey in schema.properties) {
    const propertyOptions = schema.properties[propertyKey];
    const escapedPropertyKey = /^[a-zA-Z]+$/gm.test(propertyKey) ? propertyKey : `"${propertyKey}"`;
    const isRequired = schema.required?.includes(propertyKey) ?? false;
    const isReadonly = schema[ReadonlyKind] === "Readonly";
    generatedCode += `
${isReadonly ? "readonly " : ""}${escapedPropertyKey}${!isRequired ? "?" : ""}: ${tSchemaToTsType(propertyOptions)};`;
  }
  generatedCode += `}
`;
  return generatedCode;
}

// src/util/guards.ts
import { Kind as Kind2, ReadonlyKind as ReadonlyKind2 } from "@sinclair/typebox";
function isSchemaUnion(option) {
  return option[Kind2] === "Union";
}
function isSchemaIntersect(option) {
  return option[Kind2] === "Intersect";
}
function isSchemaObject(option) {
  return option[Kind2] === "Object";
}
function isSchemaReadonly(options) {
  if (ReadonlyKind2 in options) {
    return options[ReadonlyKind2] === "Readonly";
  }
  if (isSchemaIntersect(options)) {
    return options.allOf.every((value) => value[ReadonlyKind2] === "Readonly");
  }
  if (isSchemaUnion(options)) {
    return options.anyOf.some((value) => value[ReadonlyKind2] === "Readonly");
  }
  return false;
}
function isSchemaVirtual(option) {
  if ("virtual" in option) {
    return option.virtual === true;
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => value.virtual === true);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => value.virtual === true);
  }
  return false;
}

// src/extended-types/frontend-only.ts
var FrontendOnlyKind = Symbol.for("Artesa/FrontendOnly");
function frontendOnly(schema) {
  const modifiedSchema = schema;
  modifiedSchema[FrontendOnlyKind] = true;
  return modifiedSchema;
}
function isSchemaFrontendOnly(option) {
  if (FrontendOnlyKind in option) {
    return !!option[FrontendOnlyKind];
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => FrontendOnlyKind in value && !!value[FrontendOnlyKind]);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => FrontendOnlyKind in value && !!value[FrontendOnlyKind]);
  }
  return false;
}

// src/compiler.ts
function compileSingleSchema(schema, profile, options = {}, servicePath) {
  let generatedCode = `{`;
  const spaces = options.spaces ?? 2;
  for (const propertyKey in schema.properties) {
    const propertyOptions = schema.properties[propertyKey];
    const isFrontendOnly = isSchemaFrontendOnly(propertyOptions);
    const onlyNotCreate2 = isSchemaOnlyNotCreate(propertyOptions);
    if (isFrontendOnly && profile === "base") {
      continue;
    }
    if (!isFrontendOnly && profile === "frontend") {
      continue;
    }
    if (onlyNotCreate2 && profile === "create") {
      continue;
    }
    const escapedPropertyKey = /^[a-zA-Z]+$/gm.test(propertyKey) ? propertyKey : `"${propertyKey}"`;
    const isRequired = schema.required?.includes(propertyKey) ?? false;
    const isReadonly = schema[ReadonlyKind3] === "Readonly";
    generatedCode += `
${" ".repeat(spaces)}${isReadonly ? "readonly " : ""}${escapedPropertyKey}${!isRequired ? "?" : ""}: ${tSchemaToTsType(propertyOptions)};`;
  }
  if (profile === "frontend" || profile === "baseAndFrontend") {
    for (const relationKey in schema.relations) {
      const relationConfig = schema.relations[relationKey];
      const isNullable = relationConfig.nullable;
      const escapedPropertyKey = /^[a-zA-Z]+$/gm.test(relationKey) ? relationKey : `"${relationKey}"`;
      generatedCode += `
${" ".repeat(spaces)}${escapedPropertyKey}?: ![${relationConfig.service}]!${relationConfig.asArray ? "[]" : ""}${isNullable ? " | null" : ""};`;
    }
    for (const relationKey in schema.virtual) {
      const relationConfig = schema.virtual[relationKey];
      const escapedPropertyKey = /^[a-zA-Z]+$/gm.test(relationKey) ? relationKey : `"${relationKey}"`;
      generatedCode += `
${" ".repeat(spaces)}${escapedPropertyKey}: ![${relationConfig.service}]!${relationConfig.asArray ? "[]" : ""};`;
    }
  }
  generatedCode += `
${" ".repeat(spaces)}"__service"?: "${servicePath}";`;
  generatedCode += "\n}";
  return generatedCode;
}
async function compile(schemas, options = {}) {
  let generatedCode = "";
  const serviceFrontendMap = {};
  const serviceBaseMap = {};
  const serviceCreateMap = {};
  for (const name in schemas) {
    const schema = schemas[name];
    serviceFrontendMap[schema.service] = `${name}Frontend`;
    serviceBaseMap[schema.service] = `${name}Base`;
    serviceCreateMap[schema.service] = `${name}Create`;
  }
  const isUnion = (input) => input[Kind3] === "Union";
  for (const name in schemas) {
    const modelName = `${name}Base`;
    const schema = schemas[name];
    if (isUnion(schema)) {
      generatedCode += `export type ${modelName} = ${schema.anyOf.map((value) => compileSingleSchema(value, "base", {}, schema.service)).join(" | ")};
`;
    } else {
      generatedCode += `export interface ${modelName} ${compileSingleSchema(schema, "base", {}, schema.service)};
`;
    }
  }
  for (const name in schemas) {
    const modelName = `${name}Create`;
    const schema = schemas[name];
    if (isUnion(schema)) {
      generatedCode += `export type ${modelName} = ${schema.anyOf.map((value) => compileSingleSchema(value, "create", {}, schema.service)).join(" | ")};
`;
    } else {
      generatedCode += `export interface ${modelName} ${compileSingleSchema(schema, "create", {}, schema.service)};
`;
    }
  }
  for (const name in schemas) {
    const modelName = `${name}Frontend`;
    const schema = schemas[name];
    if (isUnion(schema)) {
      generatedCode += `export type ${modelName} = ${schema.anyOf.map((value) => compileSingleSchema(value, "baseAndFrontend", {}, schema.service)).join(" | ")};
`;
    } else {
      generatedCode += `export interface ${modelName} extends ${name}Base ${compileSingleSchema(schema, "frontend", {}, schema.service)};
`;
    }
  }
  generatedCode = generatedCode.replace(/!\[(.+)\]!/gm, (match, service) => {
    return serviceFrontendMap[service];
  });
  generatedCode += `export interface ModelRegister {`;
  for (const key in serviceBaseMap) {
    const modelName = serviceBaseMap[key];
    const escapedPropertyKey = /^[a-zA-Z]+$/gm.test(key) ? key : `"${key}"`;
    generatedCode += `
${escapedPropertyKey}: ${modelName};`;
  }
  generatedCode += `
}`;
  generatedCode += `export type Model = ModelRegister[keyof ModelRegister]
`;
  generatedCode += `export interface ModelRegisterCreate {`;
  for (const key in serviceCreateMap) {
    const modelName = serviceCreateMap[key];
    const escapedPropertyKey = /^[a-zA-Z]+$/gm.test(key) ? key : `"${key}"`;
    generatedCode += `
${escapedPropertyKey}: ${modelName};`;
  }
  generatedCode += `
}`;
  generatedCode += `export type ModelCreate = ModelRegisterCreate[keyof ModelRegisterCreate]
`;
  generatedCode += `export interface ModelRegisterFrontend {`;
  for (const key in serviceFrontendMap) {
    const modelName = serviceFrontendMap[key];
    const escapedPropertyKey = /^[a-zA-Z]+$/gm.test(key) ? key : `"${key}"`;
    generatedCode += `
${escapedPropertyKey}: ${modelName};`;
  }
  generatedCode += `
}`;
  generatedCode += `export type ModelFrontend = ModelRegisterFrontend[keyof ModelRegisterFrontend]
`;
  return await prettier.format(generatedCode, {
    parser: "typescript",
    tabWidth: options.spaces ?? 2
  });
}

// src/extended-types/nullable.ts
import { Kind as Kind4, Type } from "@sinclair/typebox";
var Nullable = (schema, options) => Type.Union([schema, Type.Null()], options);
function isSchemaNullable(option) {
  return isSchemaUnion(option) && option.anyOf.some((o) => o[Kind4] === "Null");
}

// src/extended-types/relations.ts
import { Type as Type2 } from "@sinclair/typebox";

// src/extended-types/not-in-create.ts
var OnlyNotCreateKind = Symbol.for("Artesa/OnlyNotCreate");
function onlyNotCreate(schema) {
  const modifiedSchema = schema;
  modifiedSchema[OnlyNotCreateKind] = true;
  return modifiedSchema;
}
function isSchemaOnlyNotCreate(option) {
  if (OnlyNotCreateKind in option) {
    return !!option[OnlyNotCreateKind];
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => OnlyNotCreateKind in value && !!value[OnlyNotCreateKind]);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => OnlyNotCreateKind in value && !!value[OnlyNotCreateKind]);
  }
  return false;
}

// src/extended-types/relations.ts
function defineRelations(schema, mapper, virtualMapper) {
  const helper = {
    belongsTo(service, additionalOptions) {
      return {
        service,
        keyHere: "",
        keyThere: "id",
        asArray: false,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    belongsToTypeUnsafe(service, additionalOptions) {
      return {
        service,
        keyHere: "",
        keyThere: "id",
        asArray: false,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    hasMany(service, foreignKey, additionalOptions) {
      return {
        service,
        keyHere: "id",
        keyThere: foreignKey,
        asArray: true,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    hasManyTypeUnsafe(service, foreignKey, additionalOptions) {
      return {
        service,
        keyHere: "id",
        keyThere: foreignKey,
        asArray: true,
        nullable: false,
        ...additionalOptions ?? {}
      };
    }
  };
  const virtualHelper = {
    virtual(service, asArray, getPopulateData, excludeForHasChange) {
      return {
        service,
        asArray,
        virtual: true,
        excludeForHasChange,
        getPopulateData
      };
    }
  };
  const relationMap = mapper(helper);
  const virtualMap = virtualMapper(virtualHelper);
  for (const key in relationMap) {
    const relation = relationMap[key];
    relation.nameAs ||= key;
    if (!relation.keyHere) {
      relation.keyHere = `${key}Id`;
    }
  }
  for (const key in virtualMap) {
    const relation = virtualMap[key];
    relation.nameAs ||= key;
  }
  const eachNonArrayRelation = Object.keys(relationMap).map((key) => relationMap[key]).filter((relation) => !relation.asArray);
  const relationIdProperties = {};
  for (const relation of eachNonArrayRelation) {
    const backendIdKey = `${relation.nameAs}Id`;
    const frontendUuidKey = `${relation.nameAs}Uuid`;
    const isNullable = relation.nullable;
    relationIdProperties[backendIdKey] = isNullable ? Type2.Optional(Nullable(Type2.Number())) : Type2.Number();
    relationIdProperties[frontendUuidKey] = onlyNotCreate(
      frontendOnly(
        isNullable ? Type2.Optional(Nullable(Type2.String({ virtual: true }))) : Type2.String({ virtual: true })
      )
    );
  }
  relationIdProperties.id = onlyNotCreate(Type2.Number());
  relationIdProperties.uuid = frontendOnly(onlyNotCreate(Type2.String()));
  const mergedSchema = Type2.Composite([schema, Type2.Object(relationIdProperties)]);
  mergedSchema.relations = relationMap;
  mergedSchema.virtual = virtualMap;
  return mergedSchema;
}

// src/extended-types/attach-service.ts
function attachService(schema, service) {
  const modifiedService = schema;
  modifiedService.service = service;
  return modifiedService;
}

// src/extended-types/schema.ts
import { Type as Type3 } from "@sinclair/typebox";
var schemaRegister = /* @__PURE__ */ new Map();
function defineSchema(properties, service, mapper, virtualMapper) {
  if (Array.isArray(properties)) {
    const schema = attachService(
      Type3.Union(
        properties.map(
          (value) => attachService(
            defineRelations(
              Type3.Object(value),
              mapper ?? (() => ({})),
              virtualMapper ?? (() => ({}))
            ),
            service
          )
        )
      ),
      service
    );
    schemaRegister.set(service, schema);
    return schema;
  } else {
    const schema = attachService(
      defineRelations(
        Type3.Object(properties),
        mapper ?? (() => ({})),
        virtualMapper ?? (() => ({}))
      ),
      service
    );
    schemaRegister.set(service, schema);
    return schema;
  }
}

// src/extended-types/enums.ts
import { Type as Type4 } from "@sinclair/typebox";
var StringEnum = (allowedValues, options) => {
  const toRecord = {};
  allowedValues.forEach((value) => toRecord[value] = value);
  return Type4.Enum(toRecord, options);
};
var NumberEnum = (allowedValues, options) => {
  return Type4.Union(
    allowedValues.map((value) => Type4.Literal(value)),
    options
  );
};

// src/index.ts
export * from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";
import { TypeCompiler } from "@sinclair/typebox/compiler";
export {
  FrontendOnlyKind,
  Nullable,
  NumberEnum,
  OnlyNotCreateKind,
  StringEnum,
  TypeCompiler,
  Value,
  attachService,
  compile,
  compileSingleSchema,
  defineRelations,
  defineSchema,
  frontendOnly,
  isSchemaFrontendOnly,
  isSchemaIntersect,
  isSchemaNullable,
  isSchemaObject,
  isSchemaOnlyNotCreate,
  isSchemaReadonly,
  isSchemaUnion,
  isSchemaVirtual,
  onlyNotCreate,
  schemaRegister
};
