import { defineSchema, StringEnum } from "@artesa/model-compiler";
import { Type, Nullable } from "../typebox/index.js";
import { viewNameValues } from "../views/index.js";
export const CustomView = defineSchema(
  {
    name: StringEnum(viewNameValues),
    text: Type.Optional(Nullable(Type.String())),
    icon: Type.Optional(Nullable(Type.String())),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  "custom-views",
  (r) => ({
    company: r.belongsTo("companies"),
    // TODO: Check whether this is actually valid, because it is missing in the sequelize model
    data: r.hasMany("custom-view-data", "viewId")
  })
);
