import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../typebox/index.js";
export const AssignedUserEventType = defineSchema(
  {
    startsAt: Type.String({ format: "date" }),
    endsAt: Type.Optional(Nullable(Type.String({ format: "date" }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assigned-user-event-types",
  (r) => ({
    company: r.belongsTo("companies"),
    user: r.belongsTo("users"),
    assignmentEventType: r.belongsTo("assignment-event-types")
  })
);
