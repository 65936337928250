import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable, StringEnum } from "../../typebox/index.js";
import { languageDefault, languagesAvailable } from "../companies/companies.types.js";
export const User = defineSchema(
  {
    fullName: Type.String(),
    shortName: Type.Optional(Nullable(Type.String({ default: null }))),
    email: Type.String({ format: "email" }),
    password: Type.String(),
    rfid: Type.Optional(Nullable(Type.String({ default: null }))),
    lastNotificationsView: Type.Optional(Nullable(Type.Date())),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    language: Type.Optional(Nullable(StringEnum(languagesAvailable, { default: languageDefault }))),
    staffNumber: Type.Optional(Nullable(Type.String({ default: null }))),
    color: Type.Optional(Nullable(Type.String({ default: "#FFFFFF" }))),
    passwordChangedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  "users",
  (r) => ({
    company: r.belongsTo("companies"),
    createdBy: r.belongsToTypeUnsafe("users", { nullable: true }),
    role: r.belongsTo("roles", { nullable: true }),
    supervisor: r.belongsToTypeUnsafe("users", { nullable: true }),
    profilePicture: r.belongsTo("uploads", { nullable: true }),
    workingTimeModel: r.belongsTo("working-time-models", { nullable: true })
  })
);
