import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable, StringEnum } from "../../typebox/index.js";
import { userAbsenceStatusDefault, userAbsenceStatuses } from "./user-absences.types.js";
export const UserAbsence = defineSchema(
  {
    startsAt: Type.Date(),
    // TODO: refine this to be a date after startsAt
    endsAt: Type.Date(),
    approvedAt: Type.Optional(Nullable(Type.Date())),
    requestedAt: Type.Optional(Nullable(Type.Date())),
    noteForApproval: Type.Optional(Nullable(Type.String())),
    compensationApprovedAt: Type.Optional(Nullable(Type.Date())),
    compensationDeclinedAt: Type.Optional(Nullable(Type.Date())),
    declinedAt: Type.Optional(Nullable(Type.Date())),
    declinedNote: Type.Optional(Nullable(Type.String())),
    status: StringEnum(userAbsenceStatuses, { default: userAbsenceStatusDefault }),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    // virtual from server
    workDays: Type.Optional(Type.Integer({ minimum: 0, virtual: true }))
  },
  "user-absences",
  (r) => ({
    company: r.belongsTo("companies"),
    user: r.belongsTo("users"),
    type: r.belongsTo("user-absence-types"),
    needApprovalBy: r.belongsTo("users", { nullable: true }),
    compensatedBy: r.belongsTo("users", { nullable: true }),
    declinedBy: r.belongsTo("users", { nullable: true })
  })
);
