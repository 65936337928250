import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const AssignmentChat = defineSchema(
  {
    lastMessageSentAt: Type.Optional(Nullable(Type.Date())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-chats",
  (r) => ({
    company: r.belongsTo("companies"),
    assignment: r.belongsTo("stairs"),
    lastMessageSent: r.belongsTo("assignment-messages", { nullable: true })
  })
);
