import parseProps from "vue-router-parse-props";

export const defineRoutesCustomFormsCreatePdf = () => {
  return [
    {
      path: "/custom-form-pdf/:id",
      name: "ViewCustomFormPdf",
      component: () => import("./ViewCustomFormsPdf.vue"),
      meta: {
        name: "Formulare",
        icon: "pen-alt",
        hideMainHeader: true,
        hideMainMenu: true,
      },
      props: parseProps({
        id: Number,
      }),
    },
  ] satisfies RouteRecordRaw[];
};
