import { defineSchema } from "@artesa/model-compiler";
import { Type } from "../typebox/index.js";
export const AssignmentEventGroupSetting = defineSchema(
  {
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-event-group-settings",
  (r) => ({
    company: r.belongsTo("companies"),
    groupType: r.belongsTo("assignment-event-group-types"),
    standardProcess: r.belongsTo("assignment-event-standard-processes")
  })
);
