import castProps from "vue-router-parse-props";
import { viewNameEnum } from "@artesa/shared";

export const defineRoutesCustomForms = (prepend?: string) => {
  return [
    {
      path: `${prepend}forms`,
      name: viewNameEnum.ViewListForms,
      component: () => import("@/components/custom-forms/ViewListForms.vue"),
      meta: {
        name: "Formulare",
        icon: "pen-alt",
        permission: true,
      },
      children: [
        {
          name: viewNameEnum["ViewListForms.Form"],
          path: ":formId",
          component: () => import("@/components/custom-forms/DialogCustomForm.vue"),
          props: castProps({
            formId: Number,
          }),
        },
      ],
    },
  ] satisfies RouteRecordRaw[];
};
