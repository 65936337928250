import { defineSchema } from "@artesa/model-compiler";
import { Type } from "../typebox/index.js";
export const AssignmentTag = defineSchema(
  {
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    isImportant: Type.Optional(Type.Boolean({ default: true })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-tags",
  (r) => ({
    company: r.belongsTo("companies"),
    assignment: r.belongsTo("stairs"),
    type: r.belongsTo("assignment-tag-types")
  })
);
