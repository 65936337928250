import { defineSchema } from "@artesa/model-compiler";
import { Nullable, Type } from "../typebox/index.js";
export const AssignmentEventGroupType = defineSchema(
  {
    name: Type.String(),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-event-group-types",
  (r) => ({
    company: r.belongsTo("companies")
  })
);
