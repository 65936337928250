import { defineSchema, Nullable } from "@artesa/model-compiler";
import { Type } from "../../typebox/index.js";
const baseObject = Type.Object({
  name: Type.String(),
  description: Type.Optional(Nullable(Type.String())),
  required: Type.Optional(Type.Boolean({ default: false })),
  order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
  deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
  createdAt: Type.ReadonlyOptional(Type.Date()),
  updatedAt: Type.ReadonlyOptional(Type.Date())
});
function newAttributeType(type, properties) {
  return Type.Composite([
    baseObject,
    Type.Object({
      type: Type.Literal(type),
      settings: Type.Optional(Type.Object(properties, { default: {} }))
    })
  ]);
}
function composite(types) {
  return types.map((value) => value(newAttributeType).properties);
}
export const AssignmentCustomAttributeDefinition = defineSchema(
  composite([
    (n) => n("text", {}),
    (n) => n("string", {
      append: Type.Optional(Type.String()),
      prepend: Type.Optional(Type.String())
    }),
    (n) => n("integer", {}),
    (n) => n("float", {}),
    (n) => n("double", {}),
    (n) => n("date", {}),
    (n) => n("date_only", {}),
    (n) => n("time", {}),
    (n) => n("boolean", {
      default: Type.Optional(Type.Boolean())
    })
  ]),
  "assignment-custom-attribute-definitions",
  (r) => ({
    company: r.belongsTo("companies")
  })
);
