import { get, set, del } from "idb-keyval";
import type { Storage } from "@feathersjs/authentication-client";

export const host =
  import.meta.env.VITE_PRIVATE_FEATHERS_URL ||
  import.meta.env.VITE_HOST_URL ||
  (typeof window !== "undefined"
    ? `${window.location.protocol}//${window.location.hostname}:3030`
    : "http://localhost:3030");

export class AuthStorageIndexdbWrapper implements Storage {
  getItem(key: string) {
    return get(key);
  }
  setItem(key: string, value: any) {
    return set(key, value);
  }
  removeItem(key: string) {
    return del(key);
  }
}
