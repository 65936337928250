<template>
  <el-dialog
    v-if="showUpdateDialog"
    v-model="showUpdateDialog"
    :title="needRefresh ? t('update.UpdateAvailable') : t('update.Updating')"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="dialog-messagebox-[600px] dialog-center"
  >
    <span class="break-word">
      <i18n-t
        :keypath="needRefresh ? `update.UpdateAvailableMessage` : `update.UpdatingMessage`"
        scope="global"
      >
        <template #linebreak>
          <div class="h-10 flex items-center justify-center">
            <el-progress
              v-if="!needRefresh"
              :percentage="100"
              color="#007c7f"
              :indeterminate="true"
              :show-text="false"
              class="flex-1"
            />
          </div>
        </template>
        <template #url>
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          <a href="https://www.artesa.de/updates" target="_blank" class="text-primary underline">
            https://www.artesa.de/updates
          </a>
        </template>
      </i18n-t>
    </span>

    <template #footer>
      <div class="flex justify-end gap-2">
        <el-button
          type="primary"
          :loading="shouldReload || updating"
          :disabled="shouldReload || updating"
          @click="() => (shouldReload = true)"
        >
          {{ t("OK") }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
const { t } = useI18n();

const showUpdateDialog = shallowRef(false);
const updating = shallowRef(false);

const { needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegisteredSW(_swScriptUrl, registration) {
    registration?.addEventListener("updatefound", () => {
      showUpdateDialog.value = true;
    });
  },
});

const shouldReload = ref(false);

whenever(
  () => needRefresh.value,
  () => {
    refresh();
  },
);

async function refresh() {
  updating.value = true;
  try {
    const { usePushNotificationStore } = await import("@/store/web-push.store");

    try {
      const pushNotificationStore = usePushNotificationStore();

      await pushNotificationStore.updateServiceWorker().catch(async error => {
        console.error(error);
        // Just for testing in prod
        return fetch("https://ntfy.sh/9HBejvb9XEfRxFBP", {
          body: JSON.stringify(error),
          method: "POST",
          headers: {
            Title: "SW Update Error",
          },
        }).catch(() => Promise.resolve());
      });
    } catch (error) {
      console.error("Probably error because of useCompanyId", error);
    }

    await updateServiceWorker(true);
  } catch (e) {
    console.error(e);
  } finally {
    updating.value = false;
  }
}
</script>

<script lang="ts">
import { useRegisterSW } from "virtual:pwa-register/vue";
import { useI18n } from "vue-i18n";
</script>
