import { defineSchema } from "@artesa/model-compiler";
import { Type } from "../typebox/index.js";
export const AssignmentEventTaskSetting = defineSchema(
  {
    name: Type.String({ default: "" }),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-event-task-settings",
  (r) => ({
    company: r.belongsTo("companies"),
    event: r.belongsTo("assignment-event-settings"),
    standardProcess: r.belongsTo("assignment-event-standard-processes")
  })
);
