import { defineSchema, Nullable } from "@artesa/model-compiler";
import { Type } from "../../typebox/index.js";
import { assignmentCustomAttributeTypes } from "./assignment-custom-attributes.shared.js";
export const AssignmentCustomAttribute = defineSchema(
  {
    type: assignmentCustomAttributeTypes,
    // raw values
    rawValueText: Type.Optional(Nullable(Type.String())),
    rawValueString: Type.Optional(Nullable(Type.String())),
    rawValueInteger: Type.Optional(Nullable(Type.Integer())),
    rawValueFloat: Type.Optional(Nullable(Type.Number())),
    rawValueDouble: Type.Optional(Nullable(Type.Number())),
    rawValueDate: Type.Optional(Nullable(Type.Date())),
    rawValueDateAndTime: Type.Optional(Nullable(Type.Date())),
    rawValueTime: Type.Optional(Nullable(Type.Date())),
    rawValueBoolean: Type.Optional(Nullable(Type.Boolean())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-custom-attributes",
  (r) => ({
    company: r.belongsTo("companies"),
    assignment: r.belongsTo("stairs"),
    definition: r.belongsTo("assignment-custom-attribute-definitions")
  })
);
