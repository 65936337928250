import { defineSchema } from "@artesa/model-compiler";
import { Type } from "../typebox/index.js";
export const AssignmentEventUser = defineSchema(
  {
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-event-users",
  (r) => ({
    company: r.belongsTo("companies"),
    event: r.belongsTo("assignment-events"),
    user: r.belongsTo("users"),
    assignedBy: r.belongsTo("users", { nullable: true })
  })
);
