import { defineSchema } from "@artesa/model-compiler";
import { Nullable, Type } from "../typebox/index.js";
export const AssignmentTagType = defineSchema(
  {
    name: Type.String(),
    color: Type.Optional(
      Nullable(Type.RegExp(/^#[0-9a-fA-F]{8}$|#[0-9a-fA-F]{6}$|#[0-9a-fA-F]{4}$|#[0-9a-fA-F]{3}$/))
    ),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-tag-types",
  (r) => ({
    company: r.belongsTo("companies")
  })
);
