import { safeLocalStorage } from "@/helpers/localStorage";
import type { UrlQueryLocalStore } from "@/composables/useURLQuery";
import { URL_QUERY_RESTORE_STORE_KEY } from "@/composables/useURLQuery";
import type { ViewName } from "@artesa/shared";

export function changeUrlQueryLocalStorage(map: Record<string, ViewName>): void {
  const localStorage = safeLocalStorage();

  if (!localStorage) {
    return;
  }

  const store = localStorage.getItem(URL_QUERY_RESTORE_STORE_KEY);

  if (!store) {
    return;
  }

  const parsedStore = JSON.parse(store) as UrlQueryLocalStore;

  for (const key in parsedStore) {
    const value = parsedStore[key];

    for (const oldName in map) {
      const newName = map[oldName];

      if (oldName in value) {
        value[newName] = value[oldName];
        delete value[oldName];
      }
    }
  }

  localStorage.setItem(URL_QUERY_RESTORE_STORE_KEY, JSON.stringify(parsedStore));
}