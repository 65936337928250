import { defineStore } from "pinia";
import useAssignmentUserChatStore from "./feathers/services/assignment-user-chats/assignment-user-chats.pinia";
import useAssignmentChatStore from "./feathers/services/assignment-chats/assignment-chats.pinia";
import { useSmartReference, useSmartReferenceArray } from "./util/smartReference";
import type { MaybeRefOrGetter } from "@vueuse/core";
import { useQueuedAction } from "@artesa/vuetils";

export type ChatHasUnreadMessagesOptions = {
  id?: number;
  assignmentId?: number;
};

export const useChatStore = defineStore("chat", () => {
  const chatsStates = useSessionStorage<{ openChats: ChatHasUnreadMessagesOptions[] }>("chats", {
    openChats: [],
  });

  const assignmentChatStore = useAssignmentChatStore().createScope();
  const assignmentUserChatStore = useAssignmentUserChatStore().createScope();

  function findByIdOrAssignmentId<T extends ChatHasUnreadMessagesOptions>(
    arr: T[],
    ctx: ChatHasUnreadMessagesOptions,
  ) {
    return arr.find(
      value =>
        (ctx.id && value.id === ctx.id) ||
        (ctx.assignmentId && value.assignmentId === ctx.assignmentId),
    );
  }

  const assignmentIds = computed(() =>
    chatsStates.value.openChats.map(value => value.assignmentId).filter(value => !!value),
  );

  const { data: chats, hasLoaded: assignmentChatsHaveLoaded } = assignmentChatStore.useFindOnce({
    items: assignmentIds,
    params: computedDebounced(() => {
      if (!chatsStates.value.openChats.length) {
        return null;
      }

      const ids = chatsStates.value.openChats.map(value => value.id).filter(value => !!value);
      const assignmentIds = chatsStates.value.openChats
        .map(value => value.assignmentId)
        .filter(value => !!value);

      return {
        query: {
          $or: [
            {
              id: {
                $in: ids,
              },
            },
            {
              assignmentId: {
                $in: assignmentIds,
              },
            },
          ],
        },
      };
    }),
    fetchParams: ids => {
      return {
        query: {
          assignmentId: {
            $in: ids,
          },
          $limit: -1,
        },
      };
    },
  });

  function openChat(ctx: ChatHasUnreadMessagesOptions) {
    if (findByIdOrAssignmentId(chatsStates.value.openChats, ctx)) {
      return;
    }

    chatsStates.value.openChats.push(ctx);
  }

  function closeChat(uuid: string) {
    const chat = chats.value.find(value => value.uuid === uuid);

    if (!chat) {
      return;
    }

    const ctx = findByIdOrAssignmentId(chatsStates.value.openChats, {
      assignmentId: chat.assignmentId,
      id: chat.id,
    });

    if (!ctx) {
      return;
    }

    const index = chatsStates.value.openChats.indexOf(ctx);
    chatsStates.value.openChats.splice(index, 1);
  }

  // Has Unread

  const currentUser = useUser();

  type QueryInformationForChatData = {
    id?: number;
    assignmentId?: number | null;
  };

  const loadChatDataFor = useSmartReferenceArray<QueryInformationForChatData>(true);

  const { data: assignmentUserChats, haveLoaded: assignmentUserChatsHaveLoaded } =
    assignmentUserChatStore.useFind({
      params: computedDebounced(() => {
        if (!currentUser.value || chats.value.length < 1) {
          return null;
        }
        return {
          query: {
            companyId: currentUser.value?.companyId,
            chatId: {
              $in: chats.value.map(value => value.id),
            },
            userId: currentUser.value.id,
            $limit: -1,
          },
        };
      }),
    });

  /**
   * Determines whether a chat has unread messages
   * @param chatOrContext Computed chat context
   * @returns
   */
  function useHasUnreadChatMessages(_context: MaybeRefOrGetter<ChatHasUnreadMessagesOptions>) {
    const { addTrackedItem, onScopeDispose } = useSmartReference(loadChatDataFor);

    const context = computed(() => toValue(_context));

    watch(
      context,
      context => {
        if (!context) {
          onScopeDispose();
          return;
        }

        addTrackedItem(
          { ...context },
          value =>
            (!!context.id && context.id === value.id) ||
            (!!context.assignmentId && context.assignmentId === value.assignmentId),
        );
      },
      { immediate: true },
    );

    const chat = computed(() =>
      chats.value.find(
        value =>
          (context.value.id && value.id === context.value.id) ||
          (context.value.assignmentId && value.assignmentId === context.value.assignmentId),
      ),
    );
    const userChat = computed(() =>
      assignmentUserChats.value.find(value => value.chatId === chat.value?.id),
    );

    const { call: markAsRead } = useQueuedAction(async () => {
      if (
        !context.value ||
        !assignmentChatsHaveLoaded.value ||
        !assignmentUserChatsHaveLoaded.value
      ) {
        return;
      }

      if (!chat.value || !currentUser.value || !currentUser.value?.id) {
        return;
      }

      const lastMessageReadId =
        userChat.value?.lastReactionReceivedId &&
        chat.value.lastMessageSentId &&
        userChat.value.lastReactionReceivedId > chat.value.lastMessageSentId
          ? userChat.value.lastReactionReceivedId
          : chat.value.lastMessageSentId;

      if (userChat.value) {
        userChat.value.lastMessageReadId = lastMessageReadId;
        userChat.value.lastMessageReadAt = new Date();
        await assignmentUserChatStore.save(userChat.value);
      } else {
        const userChat = assignmentUserChatStore.construct({
          companyId: currentUser.value?.companyId,
          chatId: chat.value.id,
          userId: currentUser.value.id,
          lastMessageReadId: lastMessageReadId,
          lastMessageReadAt: new Date(),
        });
        await assignmentUserChatStore.save(userChat);
      }
    });

    return {
      hasUnreadMessages: computed(() => {
        if (
          !assignmentChatsHaveLoaded.value ||
          !assignmentUserChatsHaveLoaded.value ||
          !chat.value ||
          !currentUser.value
        ) {
          return false;
        }

        if (!chat.value.lastMessageSentId) {
          return false;
        }

        if (
          userChat.value &&
          userChat.value.lastMessageReadId === chat.value.lastMessageSentId &&
          (!userChat.value.lastReactionReceivedId ||
            userChat.value.lastReactionReceivedId < chat.value.lastMessageSentId)
        ) {
          return false;
        }

        if (
          chat.value.lastMessageSent?.userId &&
          chat.value.lastMessageSent?.userId === currentUser.value.id &&
          (!userChat.value ||
            !userChat.value.lastReactionReceivedId ||
            userChat.value.lastReactionReceived?.userId === currentUser.value.id ||
            userChat.value.lastReactionReceivedId < chat.value.lastMessageSentId)
        ) {
          return false;
        }

        if (
          userChat.value?.lastReactionReceivedId &&
          userChat.value.lastReactionReceivedId === userChat.value.lastMessageReadId &&
          chat.value.lastMessageSentId <= userChat.value.lastReactionReceivedId
        ) {
          return false;
        }

        return true;
      }),
      loaded: computed(
        () => assignmentChatsHaveLoaded.value && assignmentUserChatsHaveLoaded.value,
      ),
      markAsRead,
      lastMessage: computed(() => {
        return chat.value?.lastMessageSent ?? null;
      }),
      lastReaction: computed(() => {
        return userChat.value?.lastReactionReceived ?? null;
      }),
    };
  }

  return {
    chats,
    openChat,
    closeChat,
    useHasUnreadChatMessages,
  };
});
