import type { CustomerFrontend } from "@artesa/shared";

type AdditionalData = {
  "DetailedAssignment": {
    customer: CustomerFrontend;
  }
}

export const useVueRouterStore = defineStore("vue-router", () => {
  const skipRestore = ref(false);

  function afterEach() {
    if (skipRestore.value) {
      skipRestore.value = false;
    }
  }

  const data = reactive<Partial<AdditionalData>>({});

  function set<T extends keyof AdditionalData>(key: T, value: AdditionalData[T]) {
    data[key] = value;
  }

  function take<T extends keyof AdditionalData>(key: T): AdditionalData[T] | undefined {
    const result = data[key];
    delete data[key];

    return result;
  }

  return {
    skipRestore,
    afterEach,
    setAdditionalData: set,
    takeAdditionalData: take,
  };
});
