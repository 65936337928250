import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../typebox/index.js";
export const Upload = defineSchema(
  {
    description: Type.Optional(Nullable(Type.String())),
    originalName: Type.String(),
    fileName: Type.String(),
    fileSize: Type.Integer({ minimum: 0 }),
    mimeType: Type.String(),
    associatedServicePath: Type.Optional(Nullable(Type.String())),
    associatedParentServicePath: Type.Optional(Nullable(Type.String())),
    associatedId: Type.Optional(Nullable(Type.Integer({ minimum: 1 }))),
    associatedParentId: Type.Optional(Nullable(Type.Integer({ minimum: 1 }))),
    frontendId: Type.Optional(Nullable(Type.String())),
    unfinished: Type.Boolean(),
    // frontend
    blobUrl: Type.Optional(Nullable(Type.String(), { virtual: true })),
    file: Type.Optional(Type.Any({ virtual: true })),
    // virtual
    downloadToken: Type.Optional(Nullable(Type.String(), { virtual: true })),
    uploadUrl: Type.Optional(Nullable(Type.String(), { virtual: true })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  "uploads",
  (r) => ({
    company: r.belongsTo("companies"),
    message: r.belongsTo("assignment-messages", { nullable: true }),
    category: r.belongsTo("upload-categories", { nullable: true }),
    createdBy: r.belongsTo("users", { nullable: true })
  })
);
