import { defineSchema } from "@artesa/model-compiler";
import { Type } from "../typebox/index.js";
export const Holiday = defineSchema(
  {
    name: Type.String(),
    // TODO: add .refine() to check if startsAt < endsAt
    startsAt: Type.Date(),
    endsAt: Type.Date(),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "holidays",
  (r) => ({
    company: r.belongsTo("companies")
  })
);
