import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable, StringEnum } from "../../typebox/index.js";
import {
  assignmentNameToShowValues,
  COMPANY_SETTING_DAYS_TO_SHOW_DEFAULT
} from "./company-settings.utils.js";
export const CompanySetting = defineSchema(
  {
    manufacturingMessage: Type.Optional(Nullable(Type.String())),
    manufacturingTimeout: Type.Optional(Nullable(Type.Integer())),
    moserLastSync: Type.Optional(Nullable(Type.Date())),
    // TODO: type this with ViewName / viewNameValues
    disabledViews: Type.Optional(Nullable(Type.Array(Type.String()))),
    RFIDEnabled: Type.Optional(Type.Boolean({ default: false })),
    assignmentNameToShow: Type.Optional(
      Nullable(StringEnum(assignmentNameToShowValues, { default: "number" }))
    ),
    assignmentNameToShowManufacturing: Type.Optional(
      Nullable(StringEnum(assignmentNameToShowValues, { default: null }))
    ),
    weekdaysToShow: Type.Optional(Type.Array(Type.Boolean(), {
      maxItems: 7,
      minItems: 7,
      default: [...COMPANY_SETTING_DAYS_TO_SHOW_DEFAULT]
    })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "company-settings",
  (r) => ({
    address: r.belongsTo("addresses", { nullable: true })
  })
);
