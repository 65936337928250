import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../typebox/index.js";
export const Project = defineSchema(
  {
    projectNumber: Type.String(),
    foreignReference: Type.Optional(Nullable(Type.String())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  "projects",
  (r) => ({
    company: r.belongsTo("companies"),
    createdBy: r.belongsTo("users", { nullable: true })
  })
);
