(function(){"use strict";try{if(typeof document<"u"){var t=document.createElement("style");t.appendChild(document.createTextNode("._wrap_12rlz_1{overflow:hidden;position:relative}._scroll-container_12rlz_6{overflow:auto}._shadow-top_12rlz_10,._shadow-right_12rlz_11,._shadow-bottom_12rlz_12,._shadow-left_12rlz_13{position:absolute;border-radius:.5em;opacity:0;transition:opacity .4s;pointer-events:none}._shadow-top_12rlz_10,._shadow-bottom_12rlz_12{right:0;left:0;height:.5em;border-top-right-radius:0;border-top-left-radius:0;background-image:linear-gradient(#0000002b,#fff0)}._shadow-top_12rlz_10{top:0}._shadow-bottom_12rlz_12{bottom:0;transform:rotate(180deg)}._shadow-right_12rlz_11,._shadow-left_12rlz_13{top:0;bottom:0;width:.5em;border-top-left-radius:0;border-bottom-left-radius:0;background-image:linear-gradient(90deg,#0000002b,#fff0)}._shadow-right_12rlz_11{right:0;transform:rotate(180deg)}._shadow-left_12rlz_13{left:0}._is-active_12rlz_59{opacity:1}")),document.head.appendChild(t)}}catch(o){console.error("vite-plugin-css-injected-by-js",o)}})();
var mt = Object.defineProperty;
var Se = (e) => {
  throw TypeError(e);
};
var pt = (e, r, t) => r in e ? mt(e, r, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[r] = t;
var qe = (e, r, t) => pt(e, typeof r != "symbol" ? r + "" : r, t), me = (e, r, t) => r.has(e) || Se("Cannot " + t);
var y = (e, r, t) => (me(e, r, "read from private field"), t ? t.call(e) : r.get(e)), I = (e, r, t) => r.has(e) ? Se("Cannot add the same private member more than once") : r instanceof WeakSet ? r.add(e) : r.set(e, t), S = (e, r, t, n) => (me(e, r, "write to private field"), n ? n.call(e, t) : r.set(e, t), t), C = (e, r, t) => (me(e, r, "access private method"), t);
var le = (e, r, t, n) => ({
  set _(i) {
    S(e, r, i, t);
  },
  get _() {
    return y(e, r, n);
  }
});
import { getCurrentScope as yt, onScopeDispose as gt, unref as bt, ref as _, readonly as W, watch as P, isRef as we, computed as R, getCurrentInstance as wt, onMounted as Et, defineComponent as Tt, shallowRef as A, reactive as Ot, openBlock as St, createElementBlock as qt, normalizeClass as G, createElementVNode as K, normalizeStyle as ee, renderSlot as It, nextTick as Ve, toValue as k, toRef as Ue, isReactive as Ct } from "vue";
function J(e) {
  return yt() ? (gt(e), !0) : !1;
}
function xt(e, r, { enumerable: t = !1, unwrap: n = !0 } = {}) {
  for (const [i, s] of Object.entries(r))
    i !== "value" && (we(s) && n ? Object.defineProperty(e, i, {
      get() {
        return s.value;
      },
      set(d) {
        s.value = d;
      },
      enumerable: t
    }) : Object.defineProperty(e, i, { value: s, enumerable: t }));
  return e;
}
function ie(e) {
  return typeof e == "function" ? e() : bt(e);
}
const ye = typeof window < "u" && typeof document < "u";
typeof WorkerGlobalScope < "u" && globalThis instanceof WorkerGlobalScope;
const Ie = () => {
};
function He(e, r) {
  function t(...n) {
    return new Promise((i, s) => {
      Promise.resolve(e(() => r.apply(this, n), { fn: r, thisArg: this, args: n })).then(i).catch(s);
    });
  }
  return t;
}
const Qe = (e) => e();
function Ee(e, r = {}) {
  let t, n, i = Ie;
  const s = (l) => {
    clearTimeout(l), i(), i = Ie;
  };
  return (l) => {
    const a = ie(e), c = ie(r.maxWait);
    return t && s(t), a <= 0 || c !== void 0 && c <= 0 ? (n && (s(n), n = null), Promise.resolve(l())) : new Promise((o, f) => {
      i = r.rejectOnCancel ? f : o, c && !n && (n = setTimeout(() => {
        t && s(t), n = null, o(l());
      }, c)), t = setTimeout(() => {
        n && s(n), n = null, o(l());
      }, a);
    });
  };
}
function At(e = Qe) {
  const r = _(!0);
  function t() {
    r.value = !1;
  }
  function n() {
    r.value = !0;
  }
  const i = (...s) => {
    r.value && e(...s);
  };
  return { isActive: W(r), pause: t, resume: n, eventFilter: i };
}
function _t(e, r = 200, t = {}) {
  return He(
    Ee(r, t),
    e
  );
}
function jt(e, r = 200, t = {}) {
  const n = _(e.value), i = _t(() => {
    n.value = e.value;
  }, r, t);
  return P(e, () => i()), n;
}
function Ye(e, r, t = {}) {
  const {
    eventFilter: n = Qe,
    ...i
  } = t;
  return P(
    e,
    He(
      n,
      r
    ),
    i
  );
}
function Xe(e, r = 1e3, t = {}) {
  const {
    immediate: n = !0,
    immediateCallback: i = !1
  } = t;
  let s = null;
  const d = _(!1);
  function l() {
    s && (clearInterval(s), s = null);
  }
  function a() {
    d.value = !1, l();
  }
  function c() {
    const o = ie(r);
    o <= 0 || (d.value = !0, i && e(), l(), s = setInterval(e, o));
  }
  if (n && ye && c(), we(r) || typeof r == "function") {
    const o = P(r, () => {
      d.value && ye && c();
    });
    J(o);
  }
  return J(a), {
    isActive: d,
    pause: a,
    resume: c
  };
}
function Je(e, r, t = {}) {
  const {
    debounce: n = 0,
    maxWait: i = void 0,
    ...s
  } = t;
  return Ye(
    e,
    r,
    {
      ...s,
      eventFilter: Ee(n, { maxWait: i })
    }
  );
}
const Ze = ye ? window : void 0;
function Ce(e) {
  var r;
  const t = ie(e);
  return (r = t == null ? void 0 : t.$el) != null ? r : t;
}
function Pt() {
  const e = _(!1), r = wt();
  return r && Et(() => {
    e.value = !0;
  }, r), e;
}
function $t(e) {
  const r = Pt();
  return R(() => (r.value, !!e()));
}
function Nt(e, r = {}) {
  const {
    immediate: t = !0,
    fpsLimit: n = void 0,
    window: i = Ze
  } = r, s = _(!1), d = n ? 1e3 / n : null;
  let l = 0, a = null;
  function c(h) {
    if (!s.value || !i)
      return;
    l || (l = h);
    const p = h - l;
    if (d && p < d) {
      a = i.requestAnimationFrame(c);
      return;
    }
    l = h, e({ delta: p, timestamp: h }), a = i.requestAnimationFrame(c);
  }
  function o() {
    !s.value && i && (s.value = !0, l = 0, a = i.requestAnimationFrame(c));
  }
  function f() {
    s.value = !1, a != null && i && (i.cancelAnimationFrame(a), a = null);
  }
  return t && o(), J(f), {
    isActive: W(s),
    pause: f,
    resume: o
  };
}
function xe(e, r, t = {}) {
  const { window: n = Ze, ...i } = t;
  let s;
  const d = $t(() => n && "ResizeObserver" in n), l = () => {
    s && (s.disconnect(), s = void 0);
  }, a = R(() => {
    const f = ie(e);
    return Array.isArray(f) ? f.map((h) => Ce(h)) : [Ce(f)];
  }), c = P(
    a,
    (f) => {
      if (l(), d.value && n) {
        s = new ResizeObserver(r);
        for (const h of f)
          h && s.observe(h, i);
      }
    },
    { immediate: !0, flush: "post" }
  ), o = () => {
    l(), c();
  };
  return J(o), {
    isSupported: d,
    stop: o
  };
}
function zt(e = {}) {
  const {
    controls: r = !1,
    interval: t = "requestAnimationFrame"
  } = e, n = _(/* @__PURE__ */ new Date()), i = () => n.value = /* @__PURE__ */ new Date(), s = t === "requestAnimationFrame" ? Nt(i, { immediate: !0 }) : Xe(i, t, { immediate: !0 });
  return r ? {
    now: n,
    ...s
  } : n;
}
const kt = /* @__PURE__ */ Tt({
  __name: "ScrollShadowContainer",
  props: {
    zIndex: { default: void 0 }
  },
  setup(e) {
    const r = A(null), t = A(null), n = A(void 0), i = A(void 0), s = Ot({
      top: !1,
      right: !1,
      bottom: !1,
      left: !1
    });
    async function d() {
      var a, c;
      n.value = void 0, i.value = void 0, await Ve(), n.value = (a = r.value) == null ? void 0 : a.clientWidth, i.value = (c = r.value) == null ? void 0 : c.clientHeight;
    }
    function l() {
      const a = r.value;
      if (!a)
        return;
      const c = a.clientWidth < a.scrollWidth, o = a.clientHeight < a.scrollHeight, f = a.offsetWidth + a.scrollLeft, h = a.offsetHeight + a.scrollTop, p = a.scrollTop === 0, u = f > a.scrollWidth - 1, m = h > a.scrollHeight - 1, v = a.scrollLeft === 0;
      s.top = o && !p, s.right = c && !u, s.bottom = o && !m, s.left = c && !v;
    }
    return xe(r, () => {
      l();
    }), xe(t, () => {
      d();
    }), (a, c) => (St(), qt("div", {
      ref_key: "el",
      ref: t,
      class: G(a.$style.wrap)
    }, [
      K("div", {
        ref_key: "scrollContainer",
        ref: r,
        class: G(a.$style["scroll-container"]),
        style: ee({ width: n.value, height: i.value }),
        onScrollPassive: l
      }, [
        It(a.$slots, "default"),
        K("span", {
          class: G([a.$style["shadow-top"], s.top && a.$style["is-active"]]),
          style: ee({ zIndex: a.zIndex })
        }, null, 6),
        K("span", {
          class: G([a.$style["shadow-right"], s.right && a.$style["is-active"]]),
          style: ee({ zIndex: a.zIndex })
        }, null, 6),
        K("span", {
          class: G([a.$style["shadow-bottom"], s.bottom && a.$style["is-active"]]),
          style: ee({ zIndex: a.zIndex })
        }, null, 6),
        K("span", {
          class: G([a.$style["shadow-left"], s.left && a.$style["is-active"]]),
          style: ee({ zIndex: a.zIndex })
        }, null, 6)
      ], 38)
    ], 2));
  }
}), Rt = "_wrap_12rlz_1", Dt = {
  wrap: Rt,
  "scroll-container": "_scroll-container_12rlz_6",
  "shadow-top": "_shadow-top_12rlz_10",
  "shadow-right": "_shadow-right_12rlz_11",
  "shadow-bottom": "_shadow-bottom_12rlz_12",
  "shadow-left": "_shadow-left_12rlz_13",
  "is-active": "_is-active_12rlz_59"
}, Lt = (e, r) => {
  const t = e.__vccOpts || e;
  for (const [n, i] of r)
    t[n] = i;
  return t;
}, Wt = {
  $style: Dt
}, An = /* @__PURE__ */ Lt(kt, [["__cssModules", Wt]]);
var ne = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Te(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function Mt(e) {
  var r = typeof e;
  return e != null && (r == "object" || r == "function");
}
var Ke = Mt, Ft = typeof ne == "object" && ne && ne.Object === Object && ne, Bt = Ft, Gt = Bt, Vt = typeof self == "object" && self && self.Object === Object && self, Ut = Gt || Vt || Function("return this")(), et = Ut, Ht = et, Qt = function() {
  return Ht.Date.now();
}, Yt = Qt, Xt = /\s/;
function Jt(e) {
  for (var r = e.length; r-- && Xt.test(e.charAt(r)); )
    ;
  return r;
}
var Zt = Jt, Kt = Zt, er = /^\s+/;
function tr(e) {
  return e && e.slice(0, Kt(e) + 1).replace(er, "");
}
var rr = tr, nr = et, ir = nr.Symbol, tt = ir, Ae = tt, rt = Object.prototype, sr = rt.hasOwnProperty, ar = rt.toString, te = Ae ? Ae.toStringTag : void 0;
function ur(e) {
  var r = sr.call(e, te), t = e[te];
  try {
    e[te] = void 0;
    var n = !0;
  } catch {
  }
  var i = ar.call(e);
  return n && (r ? e[te] = t : delete e[te]), i;
}
var or = ur, lr = Object.prototype, cr = lr.toString;
function fr(e) {
  return cr.call(e);
}
var dr = fr, _e = tt, vr = or, hr = dr, mr = "[object Null]", pr = "[object Undefined]", je = _e ? _e.toStringTag : void 0;
function yr(e) {
  return e == null ? e === void 0 ? pr : mr : je && je in Object(e) ? vr(e) : hr(e);
}
var gr = yr;
function br(e) {
  return e != null && typeof e == "object";
}
var wr = br, Er = gr, Tr = wr, Or = "[object Symbol]";
function Sr(e) {
  return typeof e == "symbol" || Tr(e) && Er(e) == Or;
}
var qr = Sr, Ir = rr, Pe = Ke, Cr = qr, $e = NaN, xr = /^[-+]0x[0-9a-f]+$/i, Ar = /^0b[01]+$/i, _r = /^0o[0-7]+$/i, jr = parseInt;
function Pr(e) {
  if (typeof e == "number")
    return e;
  if (Cr(e))
    return $e;
  if (Pe(e)) {
    var r = typeof e.valueOf == "function" ? e.valueOf() : e;
    e = Pe(r) ? r + "" : r;
  }
  if (typeof e != "string")
    return e === 0 ? e : +e;
  e = Ir(e);
  var t = Ar.test(e);
  return t || _r.test(e) ? jr(e.slice(2), t ? 2 : 8) : xr.test(e) ? $e : +e;
}
var $r = Pr, Nr = Ke, pe = Yt, Ne = $r, zr = "Expected a function", kr = Math.max, Rr = Math.min;
function Dr(e, r, t) {
  var n, i, s, d, l, a, c = 0, o = !1, f = !1, h = !0;
  if (typeof e != "function")
    throw new TypeError(zr);
  r = Ne(r) || 0, Nr(t) && (o = !!t.leading, f = "maxWait" in t, s = f ? kr(Ne(t.maxWait) || 0, r) : s, h = "trailing" in t ? !!t.trailing : h);
  function p(E) {
    var x = n, $ = i;
    return n = i = void 0, c = E, d = e.apply($, x), d;
  }
  function u(E) {
    return c = E, l = setTimeout(g, r), o ? p(E) : d;
  }
  function m(E) {
    var x = E - a, $ = E - c, Oe = r - x;
    return f ? Rr(Oe, s - $) : Oe;
  }
  function v(E) {
    var x = E - a, $ = E - c;
    return a === void 0 || x >= r || x < 0 || f && $ >= s;
  }
  function g() {
    var E = pe();
    if (v(E))
      return T(E);
    l = setTimeout(g, m(E));
  }
  function T(E) {
    return l = void 0, h && n ? p(E) : (n = i = void 0, d);
  }
  function b() {
    l !== void 0 && clearTimeout(l), c = 0, n = a = i = l = void 0;
  }
  function O() {
    return l === void 0 ? d : T(pe());
  }
  function q() {
    var E = pe(), x = v(E);
    if (n = arguments, i = this, a = E, x) {
      if (l === void 0)
        return u(a);
      if (f)
        return clearTimeout(l), l = setTimeout(g, r), p(a);
    }
    return l === void 0 && (l = setTimeout(g, r)), d;
  }
  return q.cancel = b, q.flush = O, q;
}
var Lr = Dr;
const Wr = /* @__PURE__ */ Te(Lr);
var Mr = Object.getOwnPropertyNames, Fr = Object.getOwnPropertySymbols, Br = Object.prototype.hasOwnProperty;
function ze(e, r) {
  return function(n, i, s) {
    return e(n, i, s) && r(n, i, s);
  };
}
function ce(e) {
  return function(t, n, i) {
    if (!t || !n || typeof t != "object" || typeof n != "object")
      return e(t, n, i);
    var s = i.cache, d = s.get(t), l = s.get(n);
    if (d && l)
      return d === n && l === t;
    s.set(t, n), s.set(n, t);
    var a = e(t, n, i);
    return s.delete(t), s.delete(n), a;
  };
}
function ke(e) {
  return Mr(e).concat(Fr(e));
}
var nt = Object.hasOwn || function(e, r) {
  return Br.call(e, r);
};
function Z(e, r) {
  return e || r ? e === r : e === r || e !== e && r !== r;
}
var it = "_owner", Re = Object.getOwnPropertyDescriptor, De = Object.keys;
function Gr(e, r, t) {
  var n = e.length;
  if (r.length !== n)
    return !1;
  for (; n-- > 0; )
    if (!t.equals(e[n], r[n], n, n, e, r, t))
      return !1;
  return !0;
}
function Vr(e, r) {
  return Z(e.getTime(), r.getTime());
}
function Le(e, r, t) {
  if (e.size !== r.size)
    return !1;
  for (var n = {}, i = e.entries(), s = 0, d, l; (d = i.next()) && !d.done; ) {
    for (var a = r.entries(), c = !1, o = 0; (l = a.next()) && !l.done; ) {
      var f = d.value, h = f[0], p = f[1], u = l.value, m = u[0], v = u[1];
      !c && !n[o] && (c = t.equals(h, m, s, o, e, r, t) && t.equals(p, v, h, m, e, r, t)) && (n[o] = !0), o++;
    }
    if (!c)
      return !1;
    s++;
  }
  return !0;
}
function Ur(e, r, t) {
  var n = De(e), i = n.length;
  if (De(r).length !== i)
    return !1;
  for (var s; i-- > 0; )
    if (s = n[i], s === it && (e.$$typeof || r.$$typeof) && e.$$typeof !== r.$$typeof || !nt(r, s) || !t.equals(e[s], r[s], s, s, e, r, t))
      return !1;
  return !0;
}
function re(e, r, t) {
  var n = ke(e), i = n.length;
  if (ke(r).length !== i)
    return !1;
  for (var s, d, l; i-- > 0; )
    if (s = n[i], s === it && (e.$$typeof || r.$$typeof) && e.$$typeof !== r.$$typeof || !nt(r, s) || !t.equals(e[s], r[s], s, s, e, r, t) || (d = Re(e, s), l = Re(r, s), (d || l) && (!d || !l || d.configurable !== l.configurable || d.enumerable !== l.enumerable || d.writable !== l.writable)))
      return !1;
  return !0;
}
function Hr(e, r) {
  return Z(e.valueOf(), r.valueOf());
}
function Qr(e, r) {
  return e.source === r.source && e.flags === r.flags;
}
function We(e, r, t) {
  if (e.size !== r.size)
    return !1;
  for (var n = {}, i = e.values(), s, d; (s = i.next()) && !s.done; ) {
    for (var l = r.values(), a = !1, c = 0; (d = l.next()) && !d.done; )
      !a && !n[c] && (a = t.equals(s.value, d.value, s.value, d.value, e, r, t)) && (n[c] = !0), c++;
    if (!a)
      return !1;
  }
  return !0;
}
function Yr(e, r) {
  var t = e.length;
  if (r.length !== t)
    return !1;
  for (; t-- > 0; )
    if (e[t] !== r[t])
      return !1;
  return !0;
}
var Xr = "[object Arguments]", Jr = "[object Boolean]", Zr = "[object Date]", Kr = "[object Map]", en = "[object Number]", tn = "[object Object]", rn = "[object RegExp]", nn = "[object Set]", sn = "[object String]", an = Array.isArray, Me = typeof ArrayBuffer == "function" && ArrayBuffer.isView ? ArrayBuffer.isView : null, Fe = Object.assign, un = Object.prototype.toString.call.bind(Object.prototype.toString);
function on(e) {
  var r = e.areArraysEqual, t = e.areDatesEqual, n = e.areMapsEqual, i = e.areObjectsEqual, s = e.arePrimitiveWrappersEqual, d = e.areRegExpsEqual, l = e.areSetsEqual, a = e.areTypedArraysEqual;
  return function(o, f, h) {
    if (o === f)
      return !0;
    if (o == null || f == null || typeof o != "object" || typeof f != "object")
      return o !== o && f !== f;
    var p = o.constructor;
    if (p !== f.constructor)
      return !1;
    if (p === Object)
      return i(o, f, h);
    if (an(o))
      return r(o, f, h);
    if (Me != null && Me(o))
      return a(o, f, h);
    if (p === Date)
      return t(o, f, h);
    if (p === RegExp)
      return d(o, f, h);
    if (p === Map)
      return n(o, f, h);
    if (p === Set)
      return l(o, f, h);
    var u = un(o);
    return u === Zr ? t(o, f, h) : u === rn ? d(o, f, h) : u === Kr ? n(o, f, h) : u === nn ? l(o, f, h) : u === tn ? typeof o.then != "function" && typeof f.then != "function" && i(o, f, h) : u === Xr ? i(o, f, h) : u === Jr || u === en || u === sn ? s(o, f, h) : !1;
  };
}
function ln(e) {
  var r = e.circular, t = e.createCustomConfig, n = e.strict, i = {
    areArraysEqual: n ? re : Gr,
    areDatesEqual: Vr,
    areMapsEqual: n ? ze(Le, re) : Le,
    areObjectsEqual: n ? re : Ur,
    arePrimitiveWrappersEqual: Hr,
    areRegExpsEqual: Qr,
    areSetsEqual: n ? ze(We, re) : We,
    areTypedArraysEqual: n ? re : Yr
  };
  if (t && (i = Fe({}, i, t(i))), r) {
    var s = ce(i.areArraysEqual), d = ce(i.areMapsEqual), l = ce(i.areObjectsEqual), a = ce(i.areSetsEqual);
    i = Fe({}, i, {
      areArraysEqual: s,
      areMapsEqual: d,
      areObjectsEqual: l,
      areSetsEqual: a
    });
  }
  return i;
}
function cn(e) {
  return function(r, t, n, i, s, d, l) {
    return e(r, t, l);
  };
}
function fn(e) {
  var r = e.circular, t = e.comparator, n = e.createState, i = e.equals, s = e.strict;
  if (n)
    return function(a, c) {
      var o = n(), f = o.cache, h = f === void 0 ? r ? /* @__PURE__ */ new WeakMap() : void 0 : f, p = o.meta;
      return t(a, c, {
        cache: h,
        equals: i,
        meta: p,
        strict: s
      });
    };
  if (r)
    return function(a, c) {
      return t(a, c, {
        cache: /* @__PURE__ */ new WeakMap(),
        equals: i,
        meta: void 0,
        strict: s
      });
    };
  var d = {
    cache: void 0,
    equals: i,
    meta: void 0,
    strict: s
  };
  return function(a, c) {
    return t(a, c, d);
  };
}
var he = F();
F({ strict: !0 });
F({ circular: !0 });
F({
  circular: !0,
  strict: !0
});
F({
  createInternalComparator: function() {
    return Z;
  }
});
F({
  strict: !0,
  createInternalComparator: function() {
    return Z;
  }
});
F({
  circular: !0,
  createInternalComparator: function() {
    return Z;
  }
});
F({
  circular: !0,
  createInternalComparator: function() {
    return Z;
  },
  strict: !0
});
function F(e) {
  e === void 0 && (e = {});
  var r = e.circular, t = r === void 0 ? !1 : r, n = e.createInternalComparator, i = e.createState, s = e.strict, d = s === void 0 ? !1 : s, l = ln(e), a = on(l), c = n ? n(a) : cn(a);
  return fn({ circular: t, comparator: a, createState: i, equals: c, strict: d });
}
const B = {
  year: 365 * 24 * 60 * 60 * 1e3,
  month: 30 * 24 * 60 * 60 * 1e3,
  date: 24 * 60 * 60 * 1e3,
  hours: 60 * 60 * 1e3,
  minutes: 60 * 1e3,
  seconds: 1e3,
  milliseconds: 1
}, dn = (e, r) => ((e.year ?? 0) * B.year + (e.month ?? 0) * B.month + (e.date ?? 0) * B.date + (e.hours ?? 0) * B.hours + (e.minutes ?? 0) * B.minutes + (e.seconds ?? 0) * B.seconds + (e.milliseconds ?? 0)) / B[r];
function vn() {
  const e = {
    state: "pending"
  };
  return e.promise = new Promise((r, t) => {
    e.resolve = (n) => {
      e.state = "resolved", r(n);
    }, e.reject = (n) => {
      e.state = "rejected", t(n);
    };
  }), e;
}
const _n = (e, r) => {
  const t = typeof (r == null ? void 0 : r.interval) == "object" ? dn(r.interval, "milliseconds") : r == null ? void 0 : r.interval, n = zt({ interval: t }), i = _(e(n.value));
  return P(n, () => {
    const s = e(n.value);
    s.getTime() !== i.value.getTime() && (i.value = s);
  }), W(i);
}, jn = () => {
  const e = A(!1);
  return J(() => {
    e.value = !0;
  }), W(e);
}, Pn = (e) => {
  const r = _(k(e));
  if (!r.value) {
    const t = P(
      () => !!k(e) && !r.value,
      (n) => {
        n && (r.value = !0, t());
      }
    );
  }
  return W(r);
};
var st = { exports: {} };
/* NProgress, (c) 2013, 2014 Rico Sta. Cruz - http://ricostacruz.com/nprogress
 * @license MIT */
(function(e, r) {
  (function(t, n) {
    e.exports = n();
  })(ne, function() {
    var t = {};
    t.version = "0.2.0";
    var n = t.settings = {
      minimum: 0.08,
      easing: "ease",
      positionUsing: "",
      speed: 200,
      trickle: !0,
      trickleRate: 0.02,
      trickleSpeed: 800,
      showSpinner: !0,
      barSelector: '[role="bar"]',
      spinnerSelector: '[role="spinner"]',
      parent: "body",
      template: '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
    };
    t.configure = function(u) {
      var m, v;
      for (m in u)
        v = u[m], v !== void 0 && u.hasOwnProperty(m) && (n[m] = v);
      return this;
    }, t.status = null, t.set = function(u) {
      var m = t.isStarted();
      u = i(u, n.minimum, 1), t.status = u === 1 ? null : u;
      var v = t.render(!m), g = v.querySelector(n.barSelector), T = n.speed, b = n.easing;
      return v.offsetWidth, l(function(O) {
        n.positionUsing === "" && (n.positionUsing = t.getPositioningCSS()), a(g, d(u, T, b)), u === 1 ? (a(v, {
          transition: "none",
          opacity: 1
        }), v.offsetWidth, setTimeout(function() {
          a(v, {
            transition: "all " + T + "ms linear",
            opacity: 0
          }), setTimeout(function() {
            t.remove(), O();
          }, T);
        }, T)) : setTimeout(O, T);
      }), this;
    }, t.isStarted = function() {
      return typeof t.status == "number";
    }, t.start = function() {
      t.status || t.set(0);
      var u = function() {
        setTimeout(function() {
          t.status && (t.trickle(), u());
        }, n.trickleSpeed);
      };
      return n.trickle && u(), this;
    }, t.done = function(u) {
      return !u && !t.status ? this : t.inc(0.3 + 0.5 * Math.random()).set(1);
    }, t.inc = function(u) {
      var m = t.status;
      return m ? (typeof u != "number" && (u = (1 - m) * i(Math.random() * m, 0.1, 0.95)), m = i(m + u, 0, 0.994), t.set(m)) : t.start();
    }, t.trickle = function() {
      return t.inc(Math.random() * n.trickleRate);
    }, function() {
      var u = 0, m = 0;
      t.promise = function(v) {
        return !v || v.state() === "resolved" ? this : (m === 0 && t.start(), u++, m++, v.always(function() {
          m--, m === 0 ? (u = 0, t.done()) : t.set((u - m) / u);
        }), this);
      };
    }(), t.render = function(u) {
      if (t.isRendered()) return document.getElementById("nprogress");
      o(document.documentElement, "nprogress-busy");
      var m = document.createElement("div");
      m.id = "nprogress", m.innerHTML = n.template;
      var v = m.querySelector(n.barSelector), g = u ? "-100" : s(t.status || 0), T = document.querySelector(n.parent), b;
      return a(v, {
        transition: "all 0 linear",
        transform: "translate3d(" + g + "%,0,0)"
      }), n.showSpinner || (b = m.querySelector(n.spinnerSelector), b && p(b)), T != document.body && o(T, "nprogress-custom-parent"), T.appendChild(m), m;
    }, t.remove = function() {
      f(document.documentElement, "nprogress-busy"), f(document.querySelector(n.parent), "nprogress-custom-parent");
      var u = document.getElementById("nprogress");
      u && p(u);
    }, t.isRendered = function() {
      return !!document.getElementById("nprogress");
    }, t.getPositioningCSS = function() {
      var u = document.body.style, m = "WebkitTransform" in u ? "Webkit" : "MozTransform" in u ? "Moz" : "msTransform" in u ? "ms" : "OTransform" in u ? "O" : "";
      return m + "Perspective" in u ? "translate3d" : m + "Transform" in u ? "translate" : "margin";
    };
    function i(u, m, v) {
      return u < m ? m : u > v ? v : u;
    }
    function s(u) {
      return (-1 + u) * 100;
    }
    function d(u, m, v) {
      var g;
      return n.positionUsing === "translate3d" ? g = { transform: "translate3d(" + s(u) + "%,0,0)" } : n.positionUsing === "translate" ? g = { transform: "translate(" + s(u) + "%,0)" } : g = { "margin-left": s(u) + "%" }, g.transition = "all " + m + "ms " + v, g;
    }
    var l = /* @__PURE__ */ function() {
      var u = [];
      function m() {
        var v = u.shift();
        v && v(m);
      }
      return function(v) {
        u.push(v), u.length == 1 && m();
      };
    }(), a = /* @__PURE__ */ function() {
      var u = ["Webkit", "O", "Moz", "ms"], m = {};
      function v(O) {
        return O.replace(/^-ms-/, "ms-").replace(/-([\da-z])/gi, function(q, E) {
          return E.toUpperCase();
        });
      }
      function g(O) {
        var q = document.body.style;
        if (O in q) return O;
        for (var E = u.length, x = O.charAt(0).toUpperCase() + O.slice(1), $; E--; )
          if ($ = u[E] + x, $ in q) return $;
        return O;
      }
      function T(O) {
        return O = v(O), m[O] || (m[O] = g(O));
      }
      function b(O, q, E) {
        q = T(q), O.style[q] = E;
      }
      return function(O, q) {
        var E = arguments, x, $;
        if (E.length == 2)
          for (x in q)
            $ = q[x], $ !== void 0 && q.hasOwnProperty(x) && b(O, x, $);
        else
          b(O, E[1], E[2]);
      };
    }();
    function c(u, m) {
      var v = typeof u == "string" ? u : h(u);
      return v.indexOf(" " + m + " ") >= 0;
    }
    function o(u, m) {
      var v = h(u), g = v + m;
      c(v, m) || (u.className = g.substring(1));
    }
    function f(u, m) {
      var v = h(u), g;
      c(u, m) && (g = v.replace(" " + m + " ", " "), u.className = g.substring(1, g.length - 1));
    }
    function h(u) {
      return (" " + (u.className || "") + " ").replace(/\s+/gi, " ");
    }
    function p(u) {
      u && u.parentNode && u.parentNode.removeChild(u);
    }
    return t;
  });
})(st);
var hn = st.exports;
const V = /* @__PURE__ */ Te(hn);
function mn(e = { clearErrorOnNewExecute: !0 }) {
  const r = _(!1), t = A(null), n = A(null);
  let i = null;
  return {
    isLoading: r,
    hasError: t,
    result: n,
    fn: (d) => (...l) => i || (r.value = !0, e.useNProgress && V.start(), i = d(...l), e.clearErrorOnNewExecute && (t.value = null), e.clearOnNewExecute && (n.value = null), i.then((a) => {
      var c;
      n.value = a, (c = e.onResult) == null || c.call(e, a, ...l);
    }), i.catch((a) => {
      var c;
      t.value = a, (c = e.onError) == null || c.call(e, a, ...l);
    }), i.finally(() => {
      var a;
      e.useNProgress && V.done(), i = null, r.value = !1, (a = e.onFinally) == null || a.call(e, ...l);
    }), i)
  };
}
function $n(e, r = { clearErrorOnNewExecute: !0 }) {
  const t = mn(r), n = t.fn(e);
  return {
    ...t,
    execute: n
  };
}
var at = { exports: {} };
(function(e) {
  var r = Object.prototype.hasOwnProperty, t = "~";
  function n() {
  }
  Object.create && (n.prototype = /* @__PURE__ */ Object.create(null), new n().__proto__ || (t = !1));
  function i(a, c, o) {
    this.fn = a, this.context = c, this.once = o || !1;
  }
  function s(a, c, o, f, h) {
    if (typeof o != "function")
      throw new TypeError("The listener must be a function");
    var p = new i(o, f || a, h), u = t ? t + c : c;
    return a._events[u] ? a._events[u].fn ? a._events[u] = [a._events[u], p] : a._events[u].push(p) : (a._events[u] = p, a._eventsCount++), a;
  }
  function d(a, c) {
    --a._eventsCount === 0 ? a._events = new n() : delete a._events[c];
  }
  function l() {
    this._events = new n(), this._eventsCount = 0;
  }
  l.prototype.eventNames = function() {
    var c = [], o, f;
    if (this._eventsCount === 0) return c;
    for (f in o = this._events)
      r.call(o, f) && c.push(t ? f.slice(1) : f);
    return Object.getOwnPropertySymbols ? c.concat(Object.getOwnPropertySymbols(o)) : c;
  }, l.prototype.listeners = function(c) {
    var o = t ? t + c : c, f = this._events[o];
    if (!f) return [];
    if (f.fn) return [f.fn];
    for (var h = 0, p = f.length, u = new Array(p); h < p; h++)
      u[h] = f[h].fn;
    return u;
  }, l.prototype.listenerCount = function(c) {
    var o = t ? t + c : c, f = this._events[o];
    return f ? f.fn ? 1 : f.length : 0;
  }, l.prototype.emit = function(c, o, f, h, p, u) {
    var m = t ? t + c : c;
    if (!this._events[m]) return !1;
    var v = this._events[m], g = arguments.length, T, b;
    if (v.fn) {
      switch (v.once && this.removeListener(c, v.fn, void 0, !0), g) {
        case 1:
          return v.fn.call(v.context), !0;
        case 2:
          return v.fn.call(v.context, o), !0;
        case 3:
          return v.fn.call(v.context, o, f), !0;
        case 4:
          return v.fn.call(v.context, o, f, h), !0;
        case 5:
          return v.fn.call(v.context, o, f, h, p), !0;
        case 6:
          return v.fn.call(v.context, o, f, h, p, u), !0;
      }
      for (b = 1, T = new Array(g - 1); b < g; b++)
        T[b - 1] = arguments[b];
      v.fn.apply(v.context, T);
    } else {
      var O = v.length, q;
      for (b = 0; b < O; b++)
        switch (v[b].once && this.removeListener(c, v[b].fn, void 0, !0), g) {
          case 1:
            v[b].fn.call(v[b].context);
            break;
          case 2:
            v[b].fn.call(v[b].context, o);
            break;
          case 3:
            v[b].fn.call(v[b].context, o, f);
            break;
          case 4:
            v[b].fn.call(v[b].context, o, f, h);
            break;
          default:
            if (!T) for (q = 1, T = new Array(g - 1); q < g; q++)
              T[q - 1] = arguments[q];
            v[b].fn.apply(v[b].context, T);
        }
    }
    return !0;
  }, l.prototype.on = function(c, o, f) {
    return s(this, c, o, f, !1);
  }, l.prototype.once = function(c, o, f) {
    return s(this, c, o, f, !0);
  }, l.prototype.removeListener = function(c, o, f, h) {
    var p = t ? t + c : c;
    if (!this._events[p]) return this;
    if (!o)
      return d(this, p), this;
    var u = this._events[p];
    if (u.fn)
      u.fn === o && (!h || u.once) && (!f || u.context === f) && d(this, p);
    else {
      for (var m = 0, v = [], g = u.length; m < g; m++)
        (u[m].fn !== o || h && !u[m].once || f && u[m].context !== f) && v.push(u[m]);
      v.length ? this._events[p] = v.length === 1 ? v[0] : v : d(this, p);
    }
    return this;
  }, l.prototype.removeAllListeners = function(c) {
    var o;
    return c ? (o = t ? t + c : c, this._events[o] && d(this, o)) : (this._events = new n(), this._eventsCount = 0), this;
  }, l.prototype.off = l.prototype.removeListener, l.prototype.addListener = l.prototype.on, l.prefixed = t, l.EventEmitter = l, e.exports = l;
})(at);
var pn = at.exports;
const yn = /* @__PURE__ */ Te(pn);
class ut extends Error {
  constructor(r) {
    super(r), this.name = "TimeoutError";
  }
}
class gn extends Error {
  constructor(r) {
    super(), this.name = "AbortError", this.message = r;
  }
}
const Be = (e) => globalThis.DOMException === void 0 ? new gn(e) : new DOMException(e), Ge = (e) => {
  const r = e.reason === void 0 ? Be("This operation was aborted.") : e.reason;
  return r instanceof Error ? r : Be(r);
};
function bn(e, r) {
  const {
    milliseconds: t,
    fallback: n,
    message: i,
    customTimers: s = { setTimeout, clearTimeout }
  } = r;
  let d;
  const a = new Promise((c, o) => {
    if (typeof t != "number" || Math.sign(t) !== 1)
      throw new TypeError(`Expected \`milliseconds\` to be a positive number, got \`${t}\``);
    if (r.signal) {
      const { signal: h } = r;
      h.aborted && o(Ge(h)), h.addEventListener("abort", () => {
        o(Ge(h));
      });
    }
    if (t === Number.POSITIVE_INFINITY) {
      e.then(c, o);
      return;
    }
    const f = new ut();
    d = s.setTimeout.call(void 0, () => {
      if (n) {
        try {
          c(n());
        } catch (h) {
          o(h);
        }
        return;
      }
      typeof e.cancel == "function" && e.cancel(), i === !1 ? c() : i instanceof Error ? o(i) : (f.message = i ?? `Promise timed out after ${t} milliseconds`, o(f));
    }, t), (async () => {
      try {
        c(await e);
      } catch (h) {
        o(h);
      }
    })();
  }).finally(() => {
    a.clear();
  });
  return a.clear = () => {
    s.clearTimeout.call(void 0, d), d = void 0;
  }, a;
}
function wn(e, r, t) {
  let n = 0, i = e.length;
  for (; i > 0; ) {
    const s = Math.trunc(i / 2);
    let d = n + s;
    t(e[d], r) <= 0 ? (n = ++d, i -= s + 1) : i = s;
  }
  return n;
}
var D;
class En {
  constructor() {
    I(this, D, []);
  }
  enqueue(r, t) {
    t = {
      priority: 0,
      ...t
    };
    const n = {
      priority: t.priority,
      run: r
    };
    if (this.size && y(this, D)[this.size - 1].priority >= t.priority) {
      y(this, D).push(n);
      return;
    }
    const i = wn(y(this, D), n, (s, d) => d.priority - s.priority);
    y(this, D).splice(i, 0, n);
  }
  dequeue() {
    const r = y(this, D).shift();
    return r == null ? void 0 : r.run;
  }
  filter(r) {
    return y(this, D).filter((t) => t.priority === r.priority).map((t) => t.run);
  }
  get size() {
    return y(this, D).length;
  }
}
D = new WeakMap();
var U, H, M, se, Q, ae, N, Y, j, ue, z, X, L, oe, w, ot, lt, ct, ft, dt, fe, ge, be, de, vt, ve;
class Tn extends yn {
  // TODO: The `throwOnTimeout` option should affect the return types of `add()` and `addAll()`
  constructor(t) {
    var n, i;
    super();
    I(this, w);
    I(this, U);
    I(this, H);
    I(this, M, 0);
    I(this, se);
    I(this, Q);
    I(this, ae, 0);
    I(this, N);
    I(this, Y);
    I(this, j);
    I(this, ue);
    I(this, z, 0);
    // The `!` is needed because of https://github.com/microsoft/TypeScript/issues/32194
    I(this, X);
    I(this, L);
    I(this, oe);
    /**
        Per-operation timeout in milliseconds. Operations fulfill once `timeout` elapses if they haven't already.
    
        Applies to each future operation.
        */
    qe(this, "timeout");
    if (t = {
      carryoverConcurrencyCount: !1,
      intervalCap: Number.POSITIVE_INFINITY,
      interval: 0,
      concurrency: Number.POSITIVE_INFINITY,
      autoStart: !0,
      queueClass: En,
      ...t
    }, !(typeof t.intervalCap == "number" && t.intervalCap >= 1))
      throw new TypeError(`Expected \`intervalCap\` to be a number from 1 and up, got \`${((n = t.intervalCap) == null ? void 0 : n.toString()) ?? ""}\` (${typeof t.intervalCap})`);
    if (t.interval === void 0 || !(Number.isFinite(t.interval) && t.interval >= 0))
      throw new TypeError(`Expected \`interval\` to be a finite number >= 0, got \`${((i = t.interval) == null ? void 0 : i.toString()) ?? ""}\` (${typeof t.interval})`);
    S(this, U, t.carryoverConcurrencyCount), S(this, H, t.intervalCap === Number.POSITIVE_INFINITY || t.interval === 0), S(this, se, t.intervalCap), S(this, Q, t.interval), S(this, j, new t.queueClass()), S(this, ue, t.queueClass), this.concurrency = t.concurrency, this.timeout = t.timeout, S(this, oe, t.throwOnTimeout === !0), S(this, L, t.autoStart === !1);
  }
  get concurrency() {
    return y(this, X);
  }
  set concurrency(t) {
    if (!(typeof t == "number" && t >= 1))
      throw new TypeError(`Expected \`concurrency\` to be a number from 1 and up, got \`${t}\` (${typeof t})`);
    S(this, X, t), C(this, w, de).call(this);
  }
  async add(t, n = {}) {
    return n = {
      timeout: this.timeout,
      throwOnTimeout: y(this, oe),
      ...n
    }, new Promise((i, s) => {
      y(this, j).enqueue(async () => {
        var d;
        le(this, z)._++, le(this, M)._++;
        try {
          (d = n.signal) == null || d.throwIfAborted();
          let l = t({ signal: n.signal });
          n.timeout && (l = bn(Promise.resolve(l), { milliseconds: n.timeout })), n.signal && (l = Promise.race([l, C(this, w, vt).call(this, n.signal)]));
          const a = await l;
          i(a), this.emit("completed", a);
        } catch (l) {
          if (l instanceof ut && !n.throwOnTimeout) {
            i();
            return;
          }
          s(l), this.emit("error", l);
        } finally {
          C(this, w, ct).call(this);
        }
      }, n), this.emit("add"), C(this, w, fe).call(this);
    });
  }
  async addAll(t, n) {
    return Promise.all(t.map(async (i) => this.add(i, n)));
  }
  /**
  Start (or resume) executing enqueued tasks within concurrency limit. No need to call this if queue is not paused (via `options.autoStart = false` or by `.pause()` method.)
  */
  start() {
    return y(this, L) ? (S(this, L, !1), C(this, w, de).call(this), this) : this;
  }
  /**
  Put queue execution on hold.
  */
  pause() {
    S(this, L, !0);
  }
  /**
  Clear the queue.
  */
  clear() {
    S(this, j, new (y(this, ue))());
  }
  /**
      Can be called multiple times. Useful if you for example add additional items at a later time.
  
      @returns A promise that settles when the queue becomes empty.
      */
  async onEmpty() {
    y(this, j).size !== 0 && await C(this, w, ve).call(this, "empty");
  }
  /**
      @returns A promise that settles when the queue size is less than the given limit: `queue.size < limit`.
  
      If you want to avoid having the queue grow beyond a certain size you can `await queue.onSizeLessThan()` before adding a new item.
  
      Note that this only limits the number of items waiting to start. There could still be up to `concurrency` jobs already running that this call does not include in its calculation.
      */
  async onSizeLessThan(t) {
    y(this, j).size < t || await C(this, w, ve).call(this, "next", () => y(this, j).size < t);
  }
  /**
      The difference with `.onEmpty` is that `.onIdle` guarantees that all work from the queue has finished. `.onEmpty` merely signals that the queue is empty, but it could mean that some promises haven't completed yet.
  
      @returns A promise that settles when the queue becomes empty, and all promises have completed; `queue.size === 0 && queue.pending === 0`.
      */
  async onIdle() {
    y(this, z) === 0 && y(this, j).size === 0 || await C(this, w, ve).call(this, "idle");
  }
  /**
  Size of the queue, the number of queued items waiting to run.
  */
  get size() {
    return y(this, j).size;
  }
  /**
      Size of the queue, filtered by the given options.
  
      For example, this can be used to find the number of items remaining in the queue with a specific priority level.
      */
  sizeBy(t) {
    return y(this, j).filter(t).length;
  }
  /**
  Number of running items (no longer in the queue).
  */
  get pending() {
    return y(this, z);
  }
  /**
  Whether the queue is currently paused.
  */
  get isPaused() {
    return y(this, L);
  }
}
U = new WeakMap(), H = new WeakMap(), M = new WeakMap(), se = new WeakMap(), Q = new WeakMap(), ae = new WeakMap(), N = new WeakMap(), Y = new WeakMap(), j = new WeakMap(), ue = new WeakMap(), z = new WeakMap(), X = new WeakMap(), L = new WeakMap(), oe = new WeakMap(), w = new WeakSet(), ot = function() {
  return y(this, H) || y(this, M) < y(this, se);
}, lt = function() {
  return y(this, z) < y(this, X);
}, ct = function() {
  le(this, z)._--, C(this, w, fe).call(this), this.emit("next");
}, ft = function() {
  C(this, w, be).call(this), C(this, w, ge).call(this), S(this, Y, void 0);
}, dt = function() {
  const t = Date.now();
  if (y(this, N) === void 0) {
    const n = y(this, ae) - t;
    if (n < 0)
      S(this, M, y(this, U) ? y(this, z) : 0);
    else
      return y(this, Y) === void 0 && S(this, Y, setTimeout(() => {
        C(this, w, ft).call(this);
      }, n)), !0;
  }
  return !1;
}, fe = function() {
  if (y(this, j).size === 0)
    return y(this, N) && clearInterval(y(this, N)), S(this, N, void 0), this.emit("empty"), y(this, z) === 0 && this.emit("idle"), !1;
  if (!y(this, L)) {
    const t = !y(this, w, dt);
    if (y(this, w, ot) && y(this, w, lt)) {
      const n = y(this, j).dequeue();
      return n ? (this.emit("active"), n(), t && C(this, w, ge).call(this), !0) : !1;
    }
  }
  return !1;
}, ge = function() {
  y(this, H) || y(this, N) !== void 0 || (S(this, N, setInterval(() => {
    C(this, w, be).call(this);
  }, y(this, Q))), S(this, ae, Date.now() + y(this, Q)));
}, be = function() {
  y(this, M) === 0 && y(this, z) === 0 && y(this, N) && (clearInterval(y(this, N)), S(this, N, void 0)), S(this, M, y(this, U) ? y(this, z) : 0), C(this, w, de).call(this);
}, /**
Executes all queued functions until it reaches the limit.
*/
de = function() {
  for (; C(this, w, fe).call(this); )
    ;
}, vt = async function(t) {
  return new Promise((n, i) => {
    t.addEventListener("abort", () => {
      i(t.reason);
    }, { once: !0 });
  });
}, ve = async function(t, n) {
  return new Promise((i) => {
    const s = () => {
      n && !n() || (this.off(t, s), i());
    };
    this.on(t, s);
  });
};
function Nn(e, r = {}) {
  const t = new Tn({ concurrency: 1 }), n = A(!0), i = A(!1), s = A(null), d = A(null);
  t.on("idle", () => {
    i.value = !1, r.useNProgress && V.done();
  }).on("add", () => {
    i.value = !0, r.useNProgress && V.start();
  }).on("next", () => {
    t.size !== 0 && r.useNProgress && V.inc();
  });
  function l(...a) {
    return t.add(async () => {
      try {
        const c = await e(...a);
        return d.value = c, n.value = !1, c;
      } catch (c) {
        throw t.size === 0 && r.useNProgress && V.done(), s.value = c, c;
      }
    });
  }
  return J(() => {
    t.clear(), t.removeAllListeners();
  }), {
    call: l,
    result: d,
    hasError: s,
    isLoading: W(i),
    isPending: n
  };
}
const On = {
  s: 1e3,
  m: 1e3 * 60,
  h: 1e3 * 60 * 60,
  d: 1e3 * 60 * 60 * 24,
  w: 1e3 * 60 * 60 * 24 * 7,
  M: 1e3 * 60 * 60 * 24 * 30,
  y: 1e3 * 60 * 60 * 24 * 30 * 12
}, zn = (e, r) => {
  const t = () => e(/* @__PURE__ */ new Date()), n = _(t()), i = On[r.unit], s = Date.now(), l = e(new Date(s + i)).getTime() - s, a = _(l <= 0 ? i : l);
  return Xe(() => {
    n.value = t(), a.value !== i && (a.value = i);
  }, a), W(n);
};
function kn(e) {
  const r = A(e());
  return P(e, (t, n) => {
    he(t, n) || (r.value = t);
  }, { flush: "sync" }), R(() => r.value);
}
const Rn = (e, r = 200, t) => {
  const { maxWait: n, rejectOnCancel: i, ...s } = t || {}, d = R(e, s), l = jt(d, r, { maxWait: n, rejectOnCancel: i });
  return R(() => l.value);
};
function Dn(e) {
  const r = A(!!e);
  return [r, (n) => {
    const i = R(n), s = R(() => (r.value || (r.value = !0), n()));
    return xt(s, { bypassed: i });
  }];
}
function Sn(e) {
  return typeof e == "boolean" ? !e : we(e) ? R(() => !e.value) : () => !e();
}
function Ln(e) {
  const r = vn(), t = k(e);
  if (t)
    return r.resolve(t), r.promise;
  const n = P(e, (i) => {
    i && (n(), r.resolve(i));
  });
  return r.promise;
}
function Wn(e, r, t) {
  return R(
    {
      get() {
        return e[r];
      },
      set(n) {
        e[r] = n;
      }
    },
    t
  );
}
function Mn(e, r, t) {
  const n = R({
    get() {
      return e.value;
    },
    set(i) {
      r(i), e.value = i, t == null || t(i);
    }
  });
  return n.bypass = (i) => {
    e.value = i;
  }, n;
}
function Fn(e, r, t = {}) {
  const {
    debounce: n = 0,
    maxWait: i = void 0,
    ...s
  } = t, { eventFilter: d, pause: l, resume: a, isActive: c } = At(Ee(n, { maxWait: i }));
  return { stop: Ye(
    e,
    r,
    {
      ...s,
      eventFilter: d
    }
  ), pause: l, resume: a, isActive: c };
}
const Bn = (e, r, t) => {
  const n = Object.entries(e), i = n.map(([d]) => d), s = n.map(([, d]) => d);
  return P(
    s,
    (d, l) => {
      const a = d.reduce(
        (o, f, h) => (o[i[h]] = f, o),
        {}
      ), c = l.reduce(
        (o, f, h) => (o[i[h]] = f, o),
        {}
      );
      r(a, c);
    },
    t
  );
}, Gn = (e, r, t) => {
  const n = Object.entries(e), i = n.map(([d]) => d), s = n.map(([, d]) => d);
  return Je(
    s,
    (d, l) => {
      const a = d.reduce(
        (o, f, h) => (o[i[h]] = f, o),
        {}
      ), c = l.reduce(
        (o, f, h) => (o[i[h]] = f, o),
        {}
      );
      r(a, c);
    },
    t
  );
};
function qn(e, r, t) {
  const { onlyIf: n, compareDeepEqual: i, ...s } = t ?? {};
  if ((t == null ? void 0 : t.onlyIf) === void 0)
    return P(e, r, t);
  let d;
  function l() {
    if (!d) return;
    const p = d;
    d = void 0, p();
  }
  function a(p) {
    d = p;
  }
  const c = _(!0), o = (p, u) => {
    if (c.value = !1, !(i && he(p, u)))
      return l(), r(p, u, a);
  };
  let f;
  const h = () => {
    o(ht(e), f);
  };
  return P(Ue(n), (p) => {
    c.value && p && h();
  }), P(
    e,
    (p, u) => {
      if (f = u, !k(n)) {
        c.value = !0;
        return;
      }
      o(p, u);
    },
    s
  );
}
function In(e, r, t) {
  const { onlyIf: n, debounce: i, maxWait: s, compareDeepEqual: d, ...l } = t ?? {};
  if ((t == null ? void 0 : t.onlyIf) === void 0)
    return Je(e, r, t);
  let a;
  function c() {
    if (!a) return;
    const v = a;
    a = void 0, v();
  }
  function o(v) {
    a = v;
  }
  const f = _(!0), h = (v, g) => {
    if (f.value = !1, !(d && he(v, g)))
      return c(), r(v, g, o);
  };
  let p;
  P(Ue(n), (v) => {
    f.value && v && m();
  });
  const u = R(
    () => Wr(
      (v, g) => {
        h(v, g);
      },
      k(i) ?? 0,
      { maxWait: k(s) }
    )
  ), m = () => {
    u.value(ht(e), p);
  };
  return P(
    e,
    (v, g) => {
      if (p = g, !k(n)) {
        f.value = !0;
        return;
      }
      u.value(v, g);
    },
    l
  );
}
function ht(e) {
  return Ct(e) ? e : Array.isArray(e) ? e.map((r) => k(r)) : k(e);
}
function Vn({
  items: e,
  action: r,
  debounce: t = 0,
  requestedItems: n,
  haveLoadedItems: i,
  localOnly: s = !1,
  watchOnlyIf: d,
  watchReset: l
}) {
  const a = n || _([]), c = i || _([]), o = R(() => k(d) !== !1 && !k(s)), f = !o.value, h = A(!f), p = A(k(s));
  function u() {
    a.value = [], c.value = [], h.value = !1, p.value = e.value.length === 0;
  }
  async function m() {
    if (!e.value.length) {
      p.value = !0, h.value = !1;
      return;
    }
    const v = a.value, g = c.value, T = e.value.filter((b) => !v.find((O) => he(b, O)));
    if (!T.length) {
      p.value || (p.value = !0), h.value = !1;
      return;
    }
    v.push(...T);
    try {
      h.value = !0, await r(T), await Ve();
    } finally {
      h.value && (h.value = !1), p.value || (p.value = !0);
    }
    g.push(...T);
  }
  return In(e, m, {
    onlyIf: o,
    debounce: t,
    immediate: !f,
    deep: !0
  }), l && qn(
    l,
    async () => {
      u(), await m();
    },
    {
      onlyIf: Sn(s)
    }
  ), {
    requestedItems: a,
    isPending: W(h),
    hasLoaded: W(p),
    reset: u
  };
}
export {
  An as ScrollShadowContainer,
  kn as computedCached,
  Rn as computedDebounced,
  kn as computedDeepEqual,
  Dn as defineComputedLazy,
  Sn as negate,
  Ln as promiseWatch,
  Wn as reactiveValueToRef,
  Mn as refSetTrap,
  _n as useDateWithNow,
  jn as useDisposed,
  Pn as useLazyShow,
  $n as useMutexAction,
  Nn as useQueuedAction,
  zn as useReactiveDate,
  Vn as useRequestOnce,
  mn as useWrapAction,
  Fn as watchDebouncedPausable,
  Bn as watchMultiAsObject,
  Gn as watchMultiAsObjectDebounced,
  qn as watchOnlyIf,
  In as watchOnlyIfDebounced
};
