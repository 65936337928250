import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../typebox/index.js";
export const AssignmentEventWorkType = defineSchema(
  {
    name: Type.String(),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-event-work-types",
  (r) => ({
    company: r.belongsTo("companies"),
    eventType: r.belongsTo("assignment-event-types", { nullable: true })
  })
);
