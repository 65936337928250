import { addDays, startOfWeek, areIntervalsOverlapping, eachDayOfInterval, differenceInCalendarDays } from 'date-fns';
import _debounce from 'lodash/debounce';
import { deepEqual } from 'fast-equals';

function dateOrNumberToNumber(value) {
  if (typeof value === "number") {
    return value;
  }
  return value.getTime();
}
function dateOrNumberToDate(value) {
  if (typeof value === "number") {
    return new Date(value);
  }
  return value;
}

function getDateId(date) {
  if (typeof date === "string")
    return date;
  const dateAsDate = dateOrNumberToDate(date);
  return `${dateAsDate.getFullYear()}-${(dateAsDate.getMonth() + 1).toString().padStart(2, "0")}-${dateAsDate.getDate().toString().padStart(2, "0")}`;
}

const addDaysToDateId = (dateId, days) => getDateId(addDays(new Date(dateId), days));

const isDateIdAfter = (dateId, otherDateId) => new Date(dateId) > new Date(otherDateId);

const dateSetValueInMilliseconds = {
  year: 365 * 24 * 60 * 60 * 1e3,
  month: 30 * 24 * 60 * 60 * 1e3,
  date: 24 * 60 * 60 * 1e3,
  hours: 60 * 60 * 1e3,
  minutes: 60 * 1e3,
  seconds: 1e3,
  milliseconds: 1
};
const dateSetValueGetFunctionMap = {
  year: "getFullYear",
  month: "getMonth",
  date: "getDate",
  hours: "getHours",
  minutes: "getMinutes",
  seconds: "getSeconds",
  milliseconds: "getMilliseconds"
};

const dateSetValueTo = (duration, resolution) => ((duration.year ?? 0) * dateSetValueInMilliseconds.year + (duration.month ?? 0) * dateSetValueInMilliseconds.month + (duration.date ?? 0) * dateSetValueInMilliseconds.date + (duration.hours ?? 0) * dateSetValueInMilliseconds.hours + (duration.minutes ?? 0) * dateSetValueInMilliseconds.minutes + (duration.seconds ?? 0) * dateSetValueInMilliseconds.seconds + (duration.milliseconds ?? 0)) / dateSetValueInMilliseconds[resolution];

const dateSetValues = [
  "year",
  "month",
  "date",
  "hours",
  "minutes",
  "seconds",
  "milliseconds"
];

const pickValuesFromDateSet = (duration, options) => {
  const result = {};
  for (let i = 0, n = dateSetValues.length; i < n; i++) {
    const dateValue = dateSetValues[i];
    if (!options.includes(dateValue)) {
      continue;
    }
    result[dateValue] = duration[dateValue];
  }
  return result;
};

const numberToDateSetValue = (num, options) => {
  const { unitInput = "milliseconds" } = options || {};
  const result = {};
  let numInMilliSeconds = Math.round(num * dateSetValueInMilliseconds[unitInput]);
  for (let i = 0, n = dateSetValues.length; i < n; i++) {
    const dateValue = dateSetValues[i];
    result[dateValue] = Math.floor(numInMilliSeconds / dateSetValueInMilliseconds[dateValue]);
    numInMilliSeconds = numInMilliSeconds % dateSetValueInMilliseconds[dateValue];
  }
  return options?.pick ? pickValuesFromDateSet(result, options.pick) : result;
};

const getSetValueFromDate = (date, options) => {
  const result = {};
  for (let i = 0, n = dateSetValues.length; i < n; i++) {
    const dateValue = dateSetValues[i];
    if (!options.includes(dateValue)) {
      continue;
    }
    const get = dateSetValueGetFunctionMap[dateValue];
    result[dateValue] = date[get]();
  }
  return result;
};

function truncateDate(_date, resolution, options) {
  const date = new Date(_date);
  if (resolution === "seconds") {
    return new Date(Math.floor(date.valueOf() / 1e3) * 1e3);
  } else if (resolution === "minutes") {
    return new Date(Math.floor(date.valueOf() / 6e4) * 6e4);
  } else if (resolution === "hours") {
    return new Date(Math.floor(date.valueOf() / 36e5) * 36e5);
  } else if (resolution === "date") {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  } else if (resolution === "week") {
    return startOfWeek(date, { weekStartsOn: options?.weekStartsOn ?? 0 });
  } else if (resolution === "month") {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  } else if (resolution === "year") {
    return new Date(date.getFullYear(), 0, 1);
  } else if (resolution === "decade") {
    const year = new Date(date).getFullYear();
    const decade = Math.floor(year / 10) * 10;
    return new Date(decade, 0, 1);
  }
  return date;
}

function sortIntervalsBy(intervals, key) {
  return [...intervals].sort((a, b) => {
    const aDate = dateOrNumberToNumber(a[key]);
    const bDate = dateOrNumberToNumber(b[key]);
    if (aDate < bDate) {
      return -1;
    }
    if (aDate > bDate) {
      return 1;
    }
    return 0;
  });
}

function dateOrNumberIntervalToDate(interval) {
  return {
    start: dateOrNumberToDate(interval.start),
    end: dateOrNumberToDate(interval.end)
  };
}

function isNonNullable(value) {
  return value !== null && value !== void 0;
}
function isObject(val) {
  return !!val && typeof val === "object" && !Array.isArray(val);
}
function isValidDate(date) {
  return !!date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

function isInterval(interval) {
  return isObject(interval) && "start" in interval && "end" in interval && isNonNullable(interval.start) && isNonNullable(interval.end) && (typeof interval.start === "number" && typeof interval.end === "number" || isValidDate(interval.start) && isValidDate(interval.end));
}
function isValidInterval(interval) {
  return isInterval(interval) && interval.start < interval.end;
}

function truncateDatesOfInterval(interval, trunc) {
  return {
    start: truncateDate(interval.start, trunc),
    end: truncateDate(interval.end, trunc)
  };
}

function combineIntervals(input, options) {
  const { ignoreGapSmallerThan = 0, truncate = void 0 } = options || {};
  if (!options?.skipFilterValidIntervals) {
    input = input.filter(isValidInterval);
  }
  if (truncate) {
    input = input.map((interval) => truncateDatesOfInterval(interval, truncate));
  }
  if (input.length === 1) {
    return [dateOrNumberIntervalToDate(input[0])];
  } else if (input.length === 0) {
    return [];
  }
  let remainingIntervals = sortIntervalsBy(
    input.map((x) => ({ start: dateOrNumberToNumber(x.start), end: dateOrNumberToNumber(x.end) })),
    "start"
  );
  const intervals = [];
  while (remainingIntervals.length) {
    const entry = remainingIntervals[0];
    const { start } = entry;
    let { end } = entry;
    let reconsiderCurrentRange = true;
    while (reconsiderCurrentRange) {
      reconsiderCurrentRange = false;
      for (let i = 1; i < remainingIntervals.length; i++) {
        const value = remainingIntervals[i];
        if (value.start > end + ignoreGapSmallerThan) {
          break;
        }
        if (value.end <= end) {
          continue;
        }
        end = value.end;
        reconsiderCurrentRange = true;
      }
    }
    intervals.push({
      start,
      end
    });
    remainingIntervals = remainingIntervals.filter((x) => x.start > end);
  }
  return intervals.map((x) => ({ start: new Date(x.start), end: new Date(x.end) }));
}

function findGapsOfIntervals(input, options) {
  let absoluteGap = 0;
  if (!options?.skipFilterValidIntervals) {
    input = input.filter(isValidInterval);
  }
  const { resolution = "milliseconds", ignoreGapSmallerThan = 0 } = options || {};
  if (!options?.combineIntervals) {
    input = combineIntervals(input, options);
  }
  const gapIntervals = [];
  for (let i = 1; i < input.length; i++) {
    const previous = input[i - 1];
    const current = input[i];
    const gap = dateOrNumberToNumber(current.start) - dateOrNumberToNumber(previous.end);
    if (gap <= ignoreGapSmallerThan) {
      continue;
    }
    absoluteGap += gap;
    gapIntervals.push({
      start: previous.end,
      end: current.start
    });
  }
  return {
    gap: resolution !== "milliseconds" ? dateSetValueTo({ milliseconds: absoluteGap }, resolution) : absoluteGap,
    gapIntervals
  };
}

const subtractIntervals = (target, intervals, options) => {
  if (!options?.skipInitialCombine) {
    intervals = combineIntervals(intervals);
  }
  if (!options?.skipFilterValidIntervals) {
    intervals = intervals.filter(isValidInterval);
  }
  if (Array.isArray(target)) {
    const targets = combineIntervals(target);
    const result2 = [];
    for (let i = 0; i < targets.length; i++) {
      const interval = targets[i];
      result2.push(...subtractIntervals(interval, intervals, { skipInitialCombine: true }));
    }
    return combineIntervals(result2);
  }
  const overlappingIntervals = intervals.filter((x) => areIntervalsOverlapping(target, x));
  if (!overlappingIntervals.length) {
    return [dateOrNumberIntervalToDate(target)];
  } else if (overlappingIntervals.some((x) => x.start <= target.start && x.end >= target.end)) {
    return [];
  }
  const result = [];
  for (let i = 0; i < overlappingIntervals.length; i++) {
    const overlapping = overlappingIntervals[i];
    let newInterval;
    if (overlapping.start <= target.start) {
      newInterval = { start: overlapping.end, end: target.end };
      result.push(...subtractIntervals(newInterval, intervals, { skipInitialCombine: true }));
    } else {
      if (overlapping.start > target.start) {
        newInterval = { start: target.start, end: overlapping.start };
        result.push(...subtractIntervals(newInterval, intervals, { skipInitialCombine: true }));
      }
      if (overlapping.end < target.end) {
        newInterval = { start: overlapping.end, end: target.end };
        result.push(...subtractIntervals(newInterval, intervals, { skipInitialCombine: true }));
      }
    }
  }
  return combineIntervals(result);
};

function intersectIntervals(target, providedSources, options) {
  let sources = Array.isArray(providedSources) ? !options?.skipInitialCombine ? providedSources : combineIntervals(providedSources) : [providedSources];
  if (!options?.skipFilterValidIntervals) {
    sources = sources.filter(isValidInterval);
  }
  if (Array.isArray(target)) {
    const targets = combineIntervals(target);
    const result2 = [];
    for (let i = 0; i < targets.length; i++) {
      const interval = targets[i];
      result2.push(...intersectIntervals(interval, sources, { skipInitialCombine: true }));
    }
    return combineIntervals(result2);
  }
  const overlappingIntervals = sources.filter((x) => areIntervalsOverlapping(target, x));
  if (!overlappingIntervals.length) {
    return [];
  }
  const result = [];
  for (let i = 0; i < overlappingIntervals.length; i++) {
    const overlapping = overlappingIntervals[i];
    result.push({
      start: new Date(
        Math.max(dateOrNumberToNumber(overlapping.start), dateOrNumberToNumber(target.start))
      ),
      end: new Date(
        Math.min(dateOrNumberToNumber(overlapping.end), dateOrNumberToNumber(target.end))
      )
    });
  }
  return combineIntervals(result);
}

function eachDayOfIntervals(input) {
  const intervals = combineIntervals(input);
  const result = [];
  for (const interval of intervals) {
    result.push(...eachDayOfInterval(interval));
  }
  return result;
}

function findIntersectingPartsOfIntervals(target, providedSources, options) {
  const fallbackEnd = options?.fallbackEnd ?? /* @__PURE__ */ new Date();
  target = {
    ...target,
    start: target.start,
    end: target.end ?? fallbackEnd
  };
  const sources = [];
  providedSources.forEach((i) => {
    const interval = {
      ...i,
      start: i.start,
      end: i.end ?? fallbackEnd
    };
    if (isValidInterval(interval)) {
      sources.push(interval);
    }
  });
  const dateNums = [target.start.getTime(), target.end.getTime()];
  const startNum = target.start.getTime();
  const endNum = target.end.getTime();
  sources.forEach((interval) => {
    const intervalStartNum = interval.start.getTime();
    if (startNum < intervalStartNum && intervalStartNum < endNum) {
      dateNums.push(interval.start.getTime());
    }
    const intervalEndNum = interval.end.getTime();
    if (startNum < intervalEndNum && intervalEndNum < endNum) {
      dateNums.push(interval.end.getTime());
    }
  });
  const uniqueDates = [...new Set(dateNums)].sort((a, b) => a - b).map((x) => new Date(x));
  const result = [];
  for (let i = 1; i < uniqueDates.length; i++) {
    const start = uniqueDates[i - 1];
    const end = uniqueDates[i];
    const overlappingIntervals = sources.filter((x) => {
      const isOverlapping = x.start <= end && x.end >= start;
      return isOverlapping;
    });
    if (overlappingIntervals.length > 0) {
      result.push({
        start,
        end,
        intervals: overlappingIntervals
      });
    }
  }
  return result;
}

const isDateInOrAgoDays = (providedDate, days) => {
  const now = Date.now();
  const date = dateOrNumberToNumber(providedDate);
  const dateInFuture = date > now;
  if (dateInFuture && days < 0 || !dateInFuture && days > 0) {
    return false;
  }
  const diffDays = differenceInCalendarDays(date, now);
  return diffDays === days;
};
const isDayAfterTomorrow = (date) => isDateInOrAgoDays(date, 2);
const isDayBeforeYesterday = (date) => isDateInOrAgoDays(date, -2);

const dateMin = (...dates) => dates.reduce((min, date) => {
  if (!min && !date) {
    return void 0;
  }
  if (!date) {
    return min;
  }
  if (!min) {
    return date;
  }
  return date < min ? date : min;
}, void 0);

const dateMax = (...dates) => dates.reduce((max, date) => {
  if (!max && !date) {
    return void 0;
  }
  if (!date) {
    return max;
  }
  if (!max) {
    return date;
  }
  return date > max ? date : max;
}, void 0);

function uniqueDates(dates) {
  return [...new Set(dates.map(dateOrNumberToNumber))].map(dateOrNumberToDate);
}

function sortDates(dates) {
  return [...dates.map(dateOrNumberToDate)].sort(
    (a, b) => dateOrNumberToNumber(a) - dateOrNumberToNumber(b)
  );
}

function asArray(value, options) {
  if (Array.isArray(value)) {
    return [value, true];
  }
  if (value == null && !options?.includeNullish) {
    return [[], false];
  }
  return [[value], false];
}

const arrayById = (items, id, options) => {
  const result = {};
  const withNotDefined = options?.withNotDefined ?? false;
  for (let i = 0, len = items.length; i < len; i++) {
    const item = items[i];
    if (!(id in item) && !withNotDefined) {
      continue;
    }
    const key = item[id];
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
  }
  return result;
};

function sortBy(array, ...keys) {
  const keysWithOrder = keys.map((key) => {
    if (Array.isArray(key)) {
      return key;
    }
    return [key, "asc"];
  });
  return [...array].sort((a, b) => {
    for (const [key, order] of keysWithOrder) {
      const valueA = a[key];
      const valueB = b[key];
      if (valueA === valueB) {
        continue;
      }
      if (order === "asc") {
        return valueA > valueB ? 1 : -1;
      }
      return valueA < valueB ? 1 : -1;
    }
    return 0;
  });
}

function toObjectByKey(items, id) {
  const result = {};
  for (const item of items) {
    const key = item[id];
    result[key] = item;
  }
  return result;
}

function PromiseObjAll(obj) {
  return Promise.all(Object.values(obj)).then((values) => {
    const result = {};
    Object.keys(obj).forEach((key, i) => {
      result[key] = values[i];
    });
    return result;
  });
}

function promiseWithResolvers() {
  const deferred = {
    state: "pending"
  };
  deferred.promise = new Promise((res, rej) => {
    deferred.resolve = (arg) => {
      deferred.state = "resolved";
      res(arg);
    };
    deferred.reject = (arg) => {
      deferred.state = "rejected";
      rej(arg);
    };
  });
  return deferred;
}

function asyncDebounce(func, wait, options) {
  let deferred = void 0;
  const debounced = _debounce(
    async (bindSelf, args) => {
      if (!deferred) {
        return;
      }
      try {
        const result = await func.bind(bindSelf)(...args);
        deferred.resolve(result);
        deferred = void 0;
      } catch (error) {
        deferred?.reject(error);
        deferred = void 0;
      }
    },
    wait,
    options
  );
  function returnFunc(...args) {
    if (!deferred) {
      deferred = promiseWithResolvers();
    }
    debounced(this, args);
    return deferred.promise;
  }
  return returnFunc;
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class MapDeepEqual extends Map {
  delete(key) {
    return super.delete(canonicalize$1(this, key));
  }
  get(key) {
    return super.get(canonicalize$1(this, key));
  }
  has(key) {
    return super.has(canonicalize$1(this, key));
  }
  set(key, value) {
    return super.set(canonicalize$1(this, key), value);
  }
  merge(other) {
    for (const [key, otherValue] of other) {
      const thisValue = this.get(key);
      if (thisValue === void 0)
        this.set(key, otherValue);
      else if (typeof thisValue.merge === "function")
        this.set(key, new thisValue.constructor(thisValue).merge(otherValue));
      else
        throw new Error(
          `Merge conflict: Key: ${JSON.stringify(key)} This Value: ${JSON.stringify(
            thisValue
          )} Other Value: ${JSON.stringify(otherValue)}`
        );
    }
    return this;
  }
  toJSON() {
    return [...this];
  }
}
function canonicalize$1(collection, element) {
  return [...collection.keys()].find((anElement) => deepEqual(element, anElement)) ?? element;
}

function setAddHas(set, value) {
  if (!set.has(value)) {
    set.add(value);
  }
  return set;
}

function setDeleteHas(set, value) {
  if (!set.has(value)) {
    return false;
  }
  return set.delete(value);
}

class SetDeepEqual extends Set {
  add(value) {
    return super.add(canonicalize(this, value));
  }
  delete(value) {
    return super.delete(canonicalize(this, value));
  }
  has(value) {
    return super.has(canonicalize(this, value));
  }
  merge(other) {
    for (const otherValue of other)
      this.add(otherValue);
    return this;
  }
  toJSON() {
    return [...this];
  }
}
function canonicalize(collection, element) {
  return [...collection.keys()].find((anElement) => deepEqual(element, anElement)) ?? element;
}

const defineConvertDurationNumber = (from, to, options) => {
  const ms = {
    ms: 1,
    s: 1e3,
    m: 1e3 * 60,
    h: 1e3 * 60 * 60,
    d: 1e3 * 60 * 60 * 24,
    w: 1e3 * 60 * 60 * 24 * 7,
    y: 1e3 * 60 * 60 * 24 * 365
  };
  return (duration) => {
    if (from === to) {
      return duration;
    }
    const result = duration * ms[from] / ms[to];
    return options?.digits !== void 0 ? parseFloat(result.toFixed(options.digits)) : result;
  };
};

function ensureStartsWith(str, prefix) {
  return str.startsWith(prefix) ? str : prefix + str;
}

function stringIsInteger(value) {
  return typeof value === "string" && !!value && !value.includes(" ") && Number.isInteger(Number(value));
}
function stringTryParseToInteger(value) {
  return stringIsInteger(value) ? Number(value) : value;
}

function indexOfRegexp(str, regex, startpos) {
  const indexOf = str.substring(startpos ?? 0).search(regex);
  return indexOf >= 0 ? indexOf + (startpos ?? 0) : indexOf;
}
function lastIndexOfRegexp(str, regex, startpos) {
  regex = regex.global ? regex : new RegExp(regex.source, "g" + (regex.ignoreCase ? "i" : "") + (regex.multiline ? "m" : ""));
  startpos = (startpos ?? 1) < 0 ? 0 : startpos ?? str.length;
  const stringToWorkWith = str.substring(0, startpos + 1);
  let lastIndexOf = -1;
  let nextStop = 0;
  let result;
  while ((result = regex.exec(stringToWorkWith)) != null) {
    lastIndexOf = result.index;
    regex.lastIndex = ++nextStop;
  }
  return lastIndexOf;
}

function trimNullable(value) {
  return value?.trim() ?? "";
}

function joinStringNullable(delimiter, ...values) {
  return values.filter(trimNullable).join(delimiter);
}

const objectPathToJsonSchemaPath = (path) => {
  return `${path.startsWith("[") ? path.match(/^\[\d+\]/) ? "" : "properties" : "properties."}` + path.replace(/\.(?![^[]*])/g, ".properties.").replace(/(?<!^)(\["[^"]*"\]|\['[^']*'\])/g, ".properties$1").replace(/(?<!^)\[\d+\]/g, ".items").replace(/^\[\d+\]/, "items");
};

const getPathsOfJsonSchemaParents = (path) => {
  const paths = [
    { path: "", type: path.startsWith("[") ? "array" : "object" }
  ];
  let startIndex = 0;
  const arrayStart = path.match(/^\[\d+\]/);
  if (arrayStart) {
    startIndex = arrayStart[0].length;
  }
  while (startIndex === 0 || startIndex < path.length) {
    const indexDot = path.indexOf(".", startIndex);
    const indexBracket = path.indexOf("[", startIndex);
    if (indexDot === -1 && indexBracket === -1) {
      break;
    }
    if (indexDot >= 0 && (indexBracket === -1 || indexDot < indexBracket)) {
      paths.push({ path: objectPathToJsonSchemaPath(path.slice(0, indexDot)), type: "object" });
      startIndex = indexDot + 1;
    } else {
      const indexCloseBracket = path.indexOf("]", indexBracket);
      const isDigit = /^\d+$/.test(path.slice(indexBracket + 1, indexCloseBracket));
      if (isDigit) {
        paths.push({
          path: objectPathToJsonSchemaPath(path.slice(0, indexBracket)),
          type: "array"
        });
      } else {
        paths.push({
          path: objectPathToJsonSchemaPath(path.slice(0, indexBracket)),
          type: "object"
        });
      }
      startIndex = indexCloseBracket + 1;
    }
  }
  return paths;
};

const iife = (fn) => fn();

function noop() {
}

function isNumber(value) {
  return typeof value !== "object" && !Number.isNaN(
    +String(
      (String(value) || "").replace(/[^0-9.\-e]/, "") !== String(value) || value === "" ? NaN : value
    )
  );
}

function isInteger(value) {
  if (typeof value === "number") {
    return Number.isInteger(value);
  }
  if (typeof value === "string") {
    return value.match(/^-?\d+$/) != null;
  }
  return false;
}

function omit(obj, keys) {
  if (!obj)
    return obj;
  const newObj = { ...obj };
  for (const key of keys) {
    if (key in newObj) {
      delete newObj[key];
    }
  }
  return newObj;
}

const defineLogger = (lvl, ...prefix) => {
  const log = lvl === true || lvl === 2 ? console.log.bind(console, ...prefix) : noop;
  const warn = lvl === true || lvl === 1 || lvl === 2 ? console.warn.bind(console, ...prefix) : noop;
  const error = console.error.bind(console, ...prefix);
  const define = (lvl2, prefix2) => {
    const lvlRoot = !lvl ? 0 : typeof lvl === "boolean" ? 2 : lvl;
    const lvlChild = !lvl2 ? 0 : typeof lvl2 === "boolean" ? 2 : lvl2;
    return defineLogger(Math.max(lvlRoot, lvlChild), ...prefix, prefix2);
  };
  return { log, warn, error, define };
};

export { MapDeepEqual, PromiseObjAll, SetDeepEqual, addDaysToDateId, arrayById, asArray, asyncDebounce, combineIntervals, dateMax, dateMin, dateOrNumberToDate, dateOrNumberToNumber, dateSetValueTo, dateSetValues, defineConvertDurationNumber, defineLogger, eachDayOfIntervals, ensureStartsWith, findGapsOfIntervals, findIntersectingPartsOfIntervals, getDateId, getPathsOfJsonSchemaParents, getSetValueFromDate, iife, indexOfRegexp, intersectIntervals, isDateIdAfter, isDateInOrAgoDays, isDayAfterTomorrow, isDayBeforeYesterday, isInteger, isInterval, isNonNullable, isNumber, isObject, isValidDate, isValidInterval, joinStringNullable, lastIndexOfRegexp, noop, numberToDateSetValue, objectPathToJsonSchemaPath, omit, pickValuesFromDateSet, promiseWithResolvers, setAddHas, setDeleteHas, sleep, sortBy, sortDates, stringIsInteger, stringTryParseToInteger, subtractIntervals, toObjectByKey, trimNullable, truncateDate, truncateDatesOfInterval, uniqueDates };
