<template>
  <el-config-provider size="default" :locale="elementPlusLocale">
    <div id="app" class="flex flex-1 w-full justify-around items-start">
      <ServiceWorkerConnector></ServiceWorkerConnector>
      <router-view></router-view>
      <LoggedIn v-if="loggedInUser"></LoggedIn>
    </div>
  </el-config-provider>
</template>

<script setup lang="ts">
const loggedInUser = useUser();
const { elementPlusLocale } = useLanguageSwitch();

const route = useRoute();
const { getRouteViewName } = useRouteViewName();

watch(
  route,
  to => {
    const name = to.name && getRouteViewName(to.name);

    const title = name ? `${name} - Artesa`.replace(/&shy;/g, "") : "Artesa";

    document.title = title;
  },
  { immediate: true },
);
</script>

<script lang="ts">
import ServiceWorkerConnector from "./service-worker/ServiceWorkerConnector.vue";
import LoggedIn from "./LoggedIn.vue";
</script>

<style lang="scss">
//Google Maps Autocomplete
.pac-container {
  z-index: 9999;
}

// Transition // Transititions

$transitions: "slide-to-right", "slide-to-left", "slide-to-top", "zoom-in", "zoom-out";

@each $t in $transitions {
  .#{$t}-enter-active,
  .#{$t}-leave-active {
    transition: 0.5s ease;
  }
}

@each $t in $transitions {
  .#{$t}-enter,
  .#{$t}-leave-to {
    opacity: 0;
  }
}

// slide
.slide-to-right-enter-from,
.slide-to-left-leave-to {
  transform: translate(-2em, 0);
}
.slide-to-left-enter-from,
.slide-to-right-leave-to {
  transform: translate(2em, 0);
}
.slide-to-top-enter-from,
.slide-to-bottom-leave-to {
  transform: translate(0, 2em);
}
.slide-to-bottom-enter-from,
.slide-to-top-leave-to {
  transform: translate(0, -2em);
}
.zoom-in-enter-from,
.zoom-out-leave-to {
  transform: scale(0.7, 0.7);
  //position: absolute;
  overflow: hidden;
  height: 100vh;
}
.zoom-out-enter-from,
.zoom-in-leave-to {
  transform: scale(1.3, 1.3);
  //position: absolute;
  overflow: hidden;
  height: 100vh;
}
</style>
