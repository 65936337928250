import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const AssignmentUserChat = defineSchema(
  {
    lastMessageReadAt: Type.Optional(Nullable(Type.Date())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-user-chats",
  (r) => ({
    company: r.belongsTo("companies"),
    chat: r.belongsTo("assignment-chats"),
    user: r.belongsTo("users"),
    lastMessageRead: r.belongsTo("assignment-messages", { nullable: true }),
    lastReactionReceived: r.belongsTo("assignment-messages", { nullable: true })
  })
);
