import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
import { architectSchema, constructionManagerSchema } from "./stairs.utils.js";
export const Assignment = defineSchema(
  {
    number: Type.Optional(Nullable(Type.String())),
    name: Type.Optional(Nullable(Type.String())),
    constructionManager: Type.Optional(constructionManagerSchema),
    architect: Type.Optional(architectSchema),
    comment: Type.Optional(Nullable(Type.String())),
    notesBackOffice: Type.Optional(Nullable(Type.String())),
    foreignReference: Type.Optional(Nullable(Type.Number())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  "stairs",
  (r) => ({
    company: r.belongsTo("companies"),
    addressProperty: r.belongsTo("addresses", { nullable: true }),
    customer: r.belongsTo("customers", { nullable: true }),
    project: r.belongsTo("projects", { nullable: true }),
    serviceWorker: r.belongsTo("users", { nullable: true }),
    type: r.belongsTo("assignment-types", { nullable: true }),
    createdBy: r.belongsTo("users", { nullable: true }),
    status: r.belongsTo("assignment-status-types", { nullable: true }),
    attributes: r.hasMany("assignment-custom-attributes", "assignmentId")
  })
);
