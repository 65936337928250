import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable, StringEnum } from "../../typebox/index.js";
import { dateAnchorValues } from "../assignment-event-types/assignment-event-types.types.js";
export const AssignmentEventSetting = defineSchema(
  {
    name: Type.Optional(Nullable(Type.String())),
    employeeCount: Type.Optional(Type.Integer({ minimum: 0, default: 0 })),
    durationDrive: Type.Optional(Type.Integer({ minimum: 0, default: 0 })),
    workDays: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    dateAnchor: Type.Optional(StringEnum(dateAnchorValues, { default: "startsAt" })),
    isOutward: Type.Optional(Type.Boolean({ default: false })),
    workingTimePerDay: Type.Optional(Type.Integer({ minimum: 0, default: 60 * 60 * 8 })),
    isAllDay: Type.Optional(Type.Boolean({ default: true })),
    startsAtTime: Type.Optional(Nullable(Type.String({ default: "06:00" }))),
    // TODO: regex for time
    manPower: Type.Optional(Type.Integer({ minimum: 0, default: 0 })),
    minUploadCount: Type.Optional(Type.Integer({ minimum: 0, default: 0 })),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  "assignment-event-settings",
  (r) => ({
    company: r.belongsTo("companies"),
    eventType: r.belongsTo("assignment-event-types"),
    group: r.belongsTo("assignment-event-group-settings", { nullable: true }),
    standardProcess: r.belongsTo("assignment-event-standard-processes")
  })
);
